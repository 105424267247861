export default {
  prefix: "fapzse",
  iconName: "custom-price",
  icon: [
    14,
    16,
    [],
    "asfcbc117",
    "M2.625 0.5C1.59473 0.5 0.75 1.34473 0.75 2.375V13.625C0.75 14.6553 1.59473 15.5 2.625 15.5H11.375C12.4053 15.5 13.25 14.6553 13.25 13.625V2.375C13.25 1.34473 12.4053 0.5 11.375 0.5H2.625ZM2.625 1.75H11.375C11.7266 1.75 12 2.02344 12 2.375V13.625C12 13.9766 11.7266 14.25 11.375 14.25H2.625C2.27344 14.25 2 13.9766 2 13.625V2.375C2 2.02344 2.27344 1.75 2.625 1.75ZM3.25 4.25V5.5H10.75V4.25H3.25ZM3.25 8V9.25H4.5V8H3.25ZM5.75 8V9.25H10.75V8H5.75ZM3.25 10.5V11.75H4.5V10.5H3.25ZM5.75 10.5V11.75H10.75V10.5H5.75Z"
  ]
};
