import _includes from "lodash/includes";
import store from "@/store";

function pageAccess(to, next) {
  const permissions = store.state.auth.permissions;

  let permitted = true;

  switch (to.name) {
    case "Services":
      if (!permissions["services-page--view-page"]) {
        permitted = false;
      }
      break;

    case "Projects":
      if (!permissions["projects-page--view-page"]) {
        permitted = false;
      }
      break;

    case "CreateProject":
      if (!permissions["create-project-page--view-page"]) {
        permitted = false;
      }
      break;

    case "Transactions":
      if (!permissions["transactions-page--view-page"]) {
        permitted = false;
      }
      break;

    case "Clients":
      if (!permissions["clients-page--view-page"]) {
        permitted = false;
      }
      break;

    case "Users":
      if (!permissions["users-page--view-page"]) {
        permitted = false;
      }
      break;

    case "ProjectsArchived":
      if (!permissions["project-archive-page--view-page"]) {
        permitted = false;
      }
      break;

    case "AHJ":
      if (!permissions["ahj-page--view-page"]) {
        permitted = false;
      }
      break;

    case "ProjectImporter":
      if (!permissions["project-importer-page--view-page"]) {
        permitted = false;
      }
      break;

    default:
      break;
  }

  if (!permitted) {
    return next({ name: "Error404", params: { "0": to.path }, force: true });
  }
}

export default function({ to, next }) {
  const unauthorized_pages = [
    "Login",
    "Registration",
    "RequestForgotPasswordLink",
    "NewPassword",
    "VerifyCode",
    "VerificationResponse",
    "NewPasswordSuccess",
    "Error423"
  ];

  if (
    !_includes(unauthorized_pages, to.name) &&
    !store.getters["auth/is_authenticated"]
  ) {
    store.commit("auth/INVALIDATE_AUTH_TOKEN");

    return next({ name: "Login", force: true });
  }

  pageAccess(to, next);

  if (
    _includes(unauthorized_pages, to.name) &&
    store.getters["auth/is_authenticated"]
  ) {
    return next({ name: "Projects" });
  }

  return next();
}
