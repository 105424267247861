export default {
  prefix: "fapzse",
  iconName: "comment-icon",
  icon: [
    25,
    25,
    [],
    "asfcbc110",
    "M12.5 1.60015e-05C9.18828 0.00531479 6.01399 1.32461 3.67411 3.66824C1.33423 6.01188 0.0200153 9.18827 0.0200195 12.5C0.0200153 15.8118 1.33423 18.9882 3.67411 21.3318C6.01399 23.6754 9.18828 24.9947 12.5 25C14.1432 25.0026 15.7708 24.6813 17.2897 24.0543C18.8086 23.4272 20.1889 22.5069 21.3518 21.3459C22.5146 20.1849 23.4372 18.8061 24.0666 17.2882C24.696 15.7703 25.02 14.1432 25.02 12.5C25.02 10.8568 24.696 9.22972 24.0666 7.71185C23.4372 6.19397 22.5146 4.8151 21.3518 3.6541C20.1889 2.49311 18.8086 1.57278 17.2897 0.945775C15.7708 0.318769 14.1432 -0.00261313 12.5 1.60015e-05V1.60015e-05ZM14.87 17.47C13.9866 18.0624 12.9311 18.3438 11.87 18.27C10.8933 18.2838 9.92394 18.1002 9.02002 17.73V15.94C9.42948 16.1401 9.8584 16.2976 10.3 16.41C10.7489 16.5374 11.2134 16.6014 11.68 16.6C12.2181 16.6409 12.7558 16.5152 13.22 16.24C13.3942 16.1089 13.5317 15.9351 13.6193 15.7355C13.7068 15.5358 13.7415 15.317 13.72 15.1C13.7396 14.8949 13.6953 14.6888 13.5933 14.5098C13.4913 14.3308 13.3365 14.1877 13.15 14.1C12.5676 13.8743 11.9437 13.7754 11.32 13.81H10.56V12.19H11.33C11.9093 12.2281 12.4892 12.1252 13.02 11.89C13.1997 11.7835 13.3457 11.6285 13.4412 11.4428C13.5367 11.257 13.5779 11.0481 13.56 10.84C13.56 10.09 13.09 9.71002 12.14 9.71002C11.8003 9.7101 11.4628 9.76411 11.14 9.87002C10.7398 10.0092 10.3598 10.2009 10.01 10.44L9.02002 9.00002C9.97238 8.32784 11.1146 7.97748 12.28 8.00002C13.1421 7.95258 13.9978 8.17261 14.73 8.63002C15.0182 8.81939 15.2524 9.08004 15.4101 9.38667C15.5678 9.69329 15.6436 10.0355 15.63 10.38C15.6363 10.9773 15.4186 11.5552 15.02 12C14.5982 12.4654 14.0367 12.7812 13.42 12.9V12.9C14.0924 12.949 14.7325 13.2078 15.25 13.64C15.4647 13.8409 15.6325 14.0866 15.7414 14.3597C15.8502 14.6328 15.8976 14.9265 15.88 15.22C15.9067 15.6488 15.8287 16.0777 15.6527 16.4697C15.4768 16.8616 15.2082 17.2049 14.87 17.47V17.47Z"
  ]
};
