export default {
  prefix: "fapzse",
  iconName: "filter",
  icon: [
    20,
    20,
    [],
    "asfcbe82",
    "M8 0.0833321C6.69839 0.0833321 5.59786 0.957028 5.18359 2.16667H0V4.25H5.18359C5.59786 5.45964 6.69839 6.33333 8 6.33333C9.654 6.33333 11 4.93125 11 3.20833C11 1.48542 9.654 0.0833321 8 0.0833321ZM13 2.16667V4.25H20V2.16667H13ZM13 7.375C11.6984 7.375 10.5979 8.24869 10.1836 9.45833H0V11.5417H10.1836C10.5979 12.7513 11.6984 13.625 13 13.625C14.654 13.625 16 12.2229 16 10.5C16 8.77708 14.654 7.375 13 7.375ZM18 9.45833V11.5417H20V9.45833H18ZM5 14.6667C3.69839 14.6667 2.59786 15.5404 2.18359 16.75H0V18.8333H2.18359C2.59786 20.043 3.69839 20.9167 5 20.9167C6.654 20.9167 8 19.5146 8 17.7917C8 16.0687 6.654 14.6667 5 14.6667ZM10 16.75V18.8333H20V16.75H10Z"
  ]
};
