export default {
  prefix: "fapzse",
  iconName: "upgrade",
  icon: [
    30,
    30,
    [],
    "asfcbc70",
    "M14.9455 2.00002C12.3725 2.01081 9.86062 2.78488 7.72787 4.22419C5.59512 5.66351 3.93745 7.70334 2.96475 10.0854C1.99206 12.4674 1.7481 15.0846 2.26376 17.6053C2.77942 20.1261 4.03151 22.4372 5.86148 24.2459C7.69146 26.0546 10.017 27.2796 12.5436 27.7658C15.0703 28.252 17.6843 27.9775 20.0549 26.977C22.4254 25.9765 24.4457 24.2951 25.86 22.1457C27.2743 19.9963 28.0189 17.4755 27.9996 14.9026C27.9653 11.4649 26.5742 8.1801 24.1292 5.76346C21.6841 3.34681 18.3832 1.99413 14.9455 2.00002ZM15.043 25.8334C12.8971 25.842 10.7971 25.2131 9.00916 24.0265C7.22124 22.8399 5.82598 21.1491 5.00034 19.1684C4.1747 17.1877 3.95588 15.0065 4.37162 12.9013C4.78736 10.7961 5.81894 8.86176 7.33552 7.34365C8.85211 5.82553 10.7854 4.79201 12.8902 4.37415C14.9949 3.9563 17.1764 4.17294 19.1579 4.99659C21.1394 5.82024 22.8316 7.21381 24.02 9.00055C25.2084 10.7873 25.8394 12.8867 25.833 15.0326C25.8244 17.892 24.6857 20.6321 22.6651 22.6554C20.6446 24.6787 17.906 25.8211 15.0466 25.8334H15.043Z" +
      "M15.1947 6.33327C13.4773 6.29474 11.7872 6.76747 10.339 7.6914C8.89079 8.61534 7.74983 9.94881 7.06099 11.5225C6.37215 13.0962 6.1665 14.839 6.47017 16.5298C6.77384 18.2206 7.57312 19.783 8.76651 21.0186C9.9599 22.2543 11.4936 23.1073 13.1728 23.4696C14.852 23.8318 16.601 23.6869 18.1976 23.0532C19.7943 22.4194 21.1666 21.3255 22.1403 19.9103C23.114 18.4951 23.6452 16.8224 23.6664 15.1047C23.6945 12.8215 22.8205 10.6194 21.2342 8.97694C19.6479 7.33451 17.4775 6.38447 15.1947 6.33327ZM18.9719 14.6388C18.3291 15.2672 17.7514 14.9386 17.2639 14.4258C17.0045 14.1024 16.776 13.7554 16.5814 13.3894L16.083 13.6458C16.083 15.343 16.083 17.0402 16.083 18.7375C16.083 19.5066 16.0108 20.3047 15.0791 20.3553C14.0139 20.4166 13.9164 19.5789 13.9164 18.7339C13.9164 17.0655 13.9164 15.3972 13.9164 13.5736C12.7933 13.9816 12.2444 15.8377 11.0816 14.6027C9.85025 13.2919 11.5583 12.6311 12.2372 11.9341C15.3175 8.778 14.6566 8.8105 17.7911 11.9125C18.1305 12.2483 18.4844 12.5769 18.8058 12.9308C19.2861 13.4544 19.6039 14.0213 18.9719 14.6388Z"
  ]
};
