export default {
  prefix: "fapzse",
  iconName: "image-2",
  icon: [
    24,
    25,
    [],
    "asfcbc125",
    "M6.13672 20C8.15707 17.7613 10.4241 15.2782 12.6634 12.7561C13.2398 12.1089 13.686 12.2006 14.2569 12.7783C15.8837 14.4115 17.5299 16.0281 19.2205 17.5947C19.7748 18.1196 19.6805 18.4807 19.2426 18.9529C18.6884 19.5556 18.0177 19.9834 17.2057 19.9889C13.3008 20 9.40142 20 6.13672 20Z" +
      "M0.0547033 16.3891C-0.15038 15.6447 0.24593 15.1031 0.830694 14.5615C2.21454 13.2782 3.56606 11.9617 4.88524 10.6118C5.47832 10.009 5.9356 9.8507 6.57857 10.559C7.4765 11.5422 9.10608 12.3727 9.22525 13.4088C9.34442 14.4448 7.5929 15.2781 6.68665 16.2253C5.73052 17.2196 4.71342 18.1696 3.71849 19.1139C2.66536 20.1305 1.86165 20.1028 0.866722 19.0195C0.548793 18.6665 0.311447 18.2484 0.171209 17.7941C0.030972 17.3398 -0.0087861 16.8604 0.0547033 16.3891Z" +
      "M19.9841 3.22339C19.9702 1.25686 18.7342 0.0347198 16.7582 0.0208319C12.2796 -0.00694396 7.80193 -0.00694396 3.3252 0.0208319C1.27991 0.0319422 0.0660372 1.26241 0.0494088 3.31505C0.0244662 6.18429 0.0494088 9.05354 0.0494088 11.9256C0.0494088 12.2033 -0.161217 12.5755 0.501146 12.9866C1.71779 11.7811 2.79586 10.4423 3.99033 9.22019C5.1848 7.99805 6.26288 8.05638 7.51278 9.16742C8.35275 9.89214 9.14777 10.6676 9.8934 11.4895C10.5253 12.22 10.9216 12.2005 11.6034 11.5089C12.9004 10.1951 14.0256 10.1951 15.3032 11.5089C16.7388 12.9977 18.0579 14.5976 19.427 16.1503L19.9813 15.6809C19.9951 11.5423 20.0145 7.38143 19.9841 3.22339ZM13.6542 8.88966C13.1593 8.89738 12.6733 8.75735 12.258 8.4874C11.8428 8.21745 11.5171 7.82978 11.3223 7.37373C11.1276 6.91768 11.0726 6.41387 11.1644 5.92642C11.2562 5.43896 11.4907 4.98991 11.8379 4.6364C12.1851 4.28289 12.6295 4.04092 13.1143 3.94127C13.5992 3.84162 14.1027 3.8888 14.5607 4.07681C15.0187 4.26482 15.4106 4.58515 15.6864 4.99705C15.9623 5.40894 16.1096 5.89377 16.1096 6.38983C16.1048 7.04463 15.8457 7.6718 15.3874 8.13844C14.929 8.60508 14.3074 8.87452 13.6542 8.88966Z" +
      "M13.625 7.22311C13.1123 7.15645 12.7769 6.87869 12.7936 6.33706C12.7914 6.23294 12.8109 6.12952 12.8507 6.03333C12.8905 5.93714 12.9498 5.85027 13.0248 5.77822C13.0998 5.70617 13.1889 5.6505 13.2865 5.61474C13.3841 5.57897 13.488 5.56389 13.5917 5.57044C13.7066 5.55933 13.8224 5.5744 13.9307 5.61453C14.0389 5.65466 14.1366 5.7188 14.2166 5.80216C14.2966 5.88552 14.3567 5.98594 14.3925 6.09588C14.4282 6.20583 14.4387 6.32247 14.4231 6.43705C14.4093 6.92035 14.0989 7.16756 13.625 7.22311Z"
  ]
};
