export default {
  prefix: "fapzse",
  iconName: "progress-three-circle",
  icon: [
    18,
    18,
    [],
    "ljfcbc104",
    "M9.51185 -2.30923e-05C7.63242 -0.00237238 5.79453 0.552795 4.2307 1.59524C2.66687 2.63768 1.44738 4.12056 0.726531 5.85625C0.00567734 7.59194 -0.184149 9.50244 0.181068 11.346C0.546285 13.1896 1.45013 14.8835 2.77826 16.2133C4.10638 17.5431 5.7991 18.449 7.64224 18.8166C9.48538 19.1841 11.3961 18.9966 13.1327 18.278C14.8693 17.5593 16.3537 16.3416 17.3981 14.7791C18.4425 13.2166 19 11.3794 19 9.49998C19 6.98247 18.0007 4.56791 16.2217 2.78666C14.4427 1.0054 12.0293 0.00312378 9.51185 -2.30923e-05ZM11.989 12.7988C11.7429 13.2422 11.3819 13.611 10.9438 13.8664C10.5058 14.1218 10.007 14.2543 9.49997 14.25C8.60518 14.2387 7.74534 13.9009 7.08222 13.3C6.74497 12.9983 6.45997 12.6373 6.82335 12.179C7.18672 11.7206 7.56672 11.8227 7.94672 12.1623C8.40272 12.5708 8.91335 12.8582 9.5451 12.8107C10.3003 12.7537 10.8395 12.3737 10.906 11.5924C10.9701 10.849 10.4832 10.4452 9.81585 10.2481C9.65935 10.2181 9.50016 10.2046 9.34085 10.2077C8.88722 10.1745 8.5761 9.95597 8.57847 9.48097C8.57379 9.38948 8.58821 9.29801 8.6208 9.21238C8.65339 9.12676 8.70345 9.04886 8.76779 8.98363C8.83212 8.9184 8.90933 8.86728 8.99449 8.83351C9.07966 8.79974 9.17092 8.78406 9.26247 8.78747C10.032 8.73997 10.545 8.38373 10.6708 7.61185C10.7091 7.35443 10.6617 7.09159 10.5359 6.86374C10.4102 6.63589 10.2131 6.45564 9.97497 6.35073C9.28622 5.97548 8.67585 6.2106 8.12247 6.66185C7.71397 6.99435 7.29122 7.42185 6.82572 6.82335C6.34122 6.17498 6.90647 5.80923 7.32447 5.46248C8.51197 4.51248 10.0795 4.5006 11.1387 5.39835C12.198 6.2961 12.3998 7.53585 11.6375 8.91335C11.3857 9.36935 11.5211 9.5736 11.7776 9.90847C12.1155 10.3052 12.3182 10.7993 12.3562 11.3191C12.3943 11.8388 12.2656 12.3572 11.989 12.7988Z"
  ]
};
