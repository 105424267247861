export default {
  prefix: "fapzse",
  iconName: "ahj-table",
  icon: [
    19,
    19,
    [],
    "asfcbc117",
    "M0.645813 6.89583H8.71873V12.1042H0.645813V6.89583ZM10.2812 6.89583H18.3541V12.1042H10.2812V6.89583ZM10.2812 5.33333H18.3541V2.98958C18.3541 1.4099 17.0687 0.125 15.4896 0.125H10.2812V5.33333ZM10.2812 13.6667V18.875H15.4896C17.0687 18.875 18.3541 17.5901 18.3541 16.0104V13.6667H10.2812ZM8.71873 13.6667H0.645813V16.0104C0.645813 17.5901 1.93123 18.875 3.5104 18.875H8.71873V13.6667ZM8.71873 5.33333V0.125H3.5104C1.93123 0.125 0.645813 1.4099 0.645813 2.98958V5.33333H8.71873Z"
  ]
};
