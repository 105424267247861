export default {
  prefix: "fapzse",
  iconName: "unsuspend",
  icon: [
    8,
    8,
    [],
    "f530",
    "M7.56726 3.1464L3 0V8L7.5654 4.84257C7.6983 4.75243 7.80786 4.62742 7.88386 4.47921C7.95986 4.331 7.99981 4.16442 8 3.99504C8.00019 3.82565 7.96059 3.65898 7.88492 3.51058C7.80924 3.36218 7.69996 3.23688 7.56726 3.1464Z" +
      "M2 0H0V8H2V0Z"
  ]
};
