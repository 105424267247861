export default {
  prefix: "fapzse",
  iconName: "arrow-return",
  icon: [
    17,
    17,
    [],
    "axbc79",
    "M7.58111 10.9861C7.58111 12.1737 7.60352 13.2603 7.55871 14.3468C7.5363 14.7258 7.40188 15.2565 7.15543 15.3828C6.93139 15.5092 6.43849 15.2817 6.21445 15.0543C4.24287 12.8812 2.29369 10.6576 0.344518 8.45925C-0.125973 7.92862 -0.103569 7.47378 0.344518 6.94314C2.3161 4.74479 4.26527 2.52116 6.23685 0.348071C6.4609 0.0953866 6.90898 -0.0562243 7.20024 0.0195811C7.37947 0.0701181 7.55871 0.626025 7.58111 0.954515C7.64832 2.0916 7.26745 3.5319 7.76035 4.26469C8.23084 4.99747 9.5751 4.94693 10.5161 5.32596C14.6833 7.06949 17.1702 11.1377 16.9909 15.8882C16.9685 16.2672 16.6549 16.6462 16.4756 17C16.2068 16.722 15.7363 16.5199 15.6691 16.1914C14.9521 12.4517 11.5019 10.5565 7.58111 10.9861Z"
  ]
};
