export default {
  prefix: "fapzse",
  iconName: "standard-comment",
  icon: [
    24,
    25,
    [],
    "asfcbc122",
    "M9.45158 19.9098C7.79693 19.9098 6.16735 19.9098 4.51269 19.9098C3.18395 19.9098 1.20338 20.297 0 19.4674C0.827327 17.0616 3.2341 16.5086 2.95832 13.4668C2.83297 11.9459 3.71044 11.0887 5.11438 11.061C9.32623 11.061 13.5381 11.061 17.7499 11.0887C19.204 11.0887 19.9311 11.9459 19.9812 13.6051C20.0063 14.9047 20.0063 16.1767 19.9812 17.4764C19.9561 19.1079 19.204 19.9375 17.7249 19.9375C14.942 19.9375 12.2093 19.9098 9.45158 19.9098Z" +
      "M9.70149 8.84879C8.09697 8.84879 6.51753 8.84879 4.91302 8.84879C3.63442 8.84879 1.82934 9.20827 0.701172 8.35104C1.20258 7.41086 2.02991 6.77485 2.50625 5.86232C2.98259 4.92214 3.03273 3.76074 2.95752 2.70994C2.83217 0.774269 3.63442 0 5.46457 0C9.47585 0 13.4871 0 17.4733 0C19.2533 0 19.9553 0.774269 19.9553 2.76525C19.9553 3.9543 19.9804 5.17101 19.9553 6.36007C19.9303 7.99156 19.1781 8.82114 17.699 8.82114C15.0415 8.87644 12.359 8.84879 9.70149 8.84879Z"
  ]
};
