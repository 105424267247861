export default {
  prefix: "fapzse",
  iconName: "truck",
  icon: [
    19,
    15,
    [],
    "asfcbc67",
    "M18.2812 10.3125H17.8125V7.14551C17.8125 6.77344 17.6631 6.41602 17.3994 6.15234L14.4727 3.22559C14.209 2.96191 13.8516 2.8125 13.4795 2.8125H12.1875V1.40625C12.1875 0.629883 11.5576 0 10.7812 0H1.40625C0.629883 0 0 0.629883 0 1.40625V10.7812C0 11.5576 0.629883 12.1875 1.40625 12.1875H1.875C1.875 13.7402 3.13477 15 4.6875 15C6.24023 15 7.5 13.7402 7.5 12.1875H11.25C11.25 13.7402 12.5098 15 14.0625 15C15.6152 15 16.875 13.7402 16.875 12.1875H18.2812C18.5391 12.1875 18.75 11.9766 18.75 11.7188V10.7812C18.75 10.5234 18.5391 10.3125 18.2812 10.3125ZM4.6875 13.5938C3.91113 13.5938 3.28125 12.9639 3.28125 12.1875C3.28125 11.4111 3.91113 10.7812 4.6875 10.7812C5.46387 10.7812 6.09375 11.4111 6.09375 12.1875C6.09375 12.9639 5.46387 13.5938 4.6875 13.5938ZM14.0625 13.5938C13.2861 13.5938 12.6562 12.9639 12.6562 12.1875C12.6562 11.4111 13.2861 10.7812 14.0625 10.7812C14.8389 10.7812 15.4688 11.4111 15.4688 12.1875C15.4688 12.9639 14.8389 13.5938 14.0625 13.5938ZM16.4062 7.5H12.1875V4.21875H13.4795L16.4062 7.14551V7.5Z"
  ]
};
