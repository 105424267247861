export default {
  prefix: "fapzse",
  iconName: "notification",
  icon: [
    15,
    15,
    [],
    "asfcbc67",
    "M22.5335 17.3047C21.5422 15.4216 20.7947 13.5352 21.1861 11.7579C21.1861 10.9366 21.1861 10.5099 21.1861 10.0832C21.154 4.33104 17.0604 0.0193123 11.6162 6.349e-05C6.17203 -0.0191853 1.9405 4.34067 1.96617 10.0191C1.96617 12.7652 1.88917 15.3734 0.362099 17.8405C-0.622798 19.4446 0.512883 21.0647 2.42493 21.1481C3.7586 21.2179 5.09501 21.2179 6.42868 21.1481C7.31412 21.0935 7.69268 21.3374 7.92688 22.2421C8.35997 23.9103 9.5919 24.8374 11.289 24.9818C12.9861 25.1262 14.449 24.4108 14.8885 22.8837C15.3826 21.1641 16.3579 21.0422 17.747 21.1577C18.5426 21.2219 19.351 21.177 20.1499 21.1577C22.7902 21.1288 23.7526 19.637 22.5335 17.3047ZM11.6259 23.0601C10.5223 23.105 9.90629 22.4474 9.61435 21.2154H13.4641C13.323 22.38 12.691 23.0088 11.6162 23.0505L11.6259 23.0601ZM20.1146 19.2296C17.2273 19.1815 14.3399 19.2136 11.4526 19.2136C8.62304 19.2136 5.79347 19.1975 2.96389 19.2136C2.12016 19.2136 1.62931 19.0307 2.2549 18.1806C4.1092 15.6622 3.85897 12.7716 3.85897 9.89715C3.85897 6.2078 6.14315 3.13762 9.46036 2.19764C10.5665 1.88222 11.7294 1.81948 12.863 2.01409C13.9967 2.20869 15.0721 2.65564 16.0097 3.32189C16.9473 3.98814 17.7232 4.85665 18.2799 5.86318C18.8366 6.8697 19.1599 7.9885 19.2259 9.13683C19.2556 9.67111 19.2556 10.2066 19.2259 10.7409C19.0848 13.3267 19.1842 15.8482 20.7787 18.0715C21.4042 18.9088 21.1668 19.2617 20.1146 19.2296Z"
  ]
};
