export default {
  prefix: "fapzse",
  iconName: "email",
  icon: [
    83,
    71,
    [],
    "asfcbc101",
    "M82.3357 35.2977C82.4457 37.2977 80.7857 37.5677 79.4857 38.2177C58.5124 48.5443 37.5491 58.8777 16.5957 69.2177C11.2157 71.8477 10.3457 71.2177 10.3757 65.2177C10.4057 59.2177 10.5557 53.2777 10.6457 47.3077C11.5057 44.5877 14.0457 44.8977 16.0257 44.5177C32.5257 41.3477 49.0257 38.2677 65.4957 34.7777C71.1457 33.8677 76.8157 32.2977 82.3357 35.2977Z" +
      "M82.3368 35.2976C76.8568 34.9176 71.3368 34.2976 65.9568 35.9276C49.2868 32.9976 32.7368 29.4176 16.1268 26.1176C14.1268 25.7276 11.6168 26.1176 10.7868 23.3476C10.6668 17.2276 10.4968 11.1076 10.4668 4.98761C10.4668 -0.622393 11.0868 -1.0124 16.0568 1.4176C29.7435 8.08427 43.4101 14.7843 57.0568 21.5176C64.3468 25.0776 71.6668 28.5776 78.9368 32.1876C80.3368 32.8876 82.3968 33.0276 82.3368 35.2976Z" +
      "M10.7863 23.3677C23.3163 26.9577 36.2663 28.5477 48.9263 31.5077C54.6363 32.8477 60.7463 32.6877 65.9263 35.9477C49.5396 39.161 33.153 42.3777 16.7663 45.5977C14.6863 46.0077 12.4463 45.9677 10.6163 47.3277C7.6163 44.2677 4.7063 41.1277 1.6163 38.1777C-0.563696 36.1177 -0.513696 34.4377 1.6163 32.4177C4.7863 29.4977 7.7463 26.3977 10.7863 23.3677Z"
  ]
};
