export default {
  prefix: "fapzse",
  iconName: "arrow-right-circle",
  icon: [
    42,
    42,
    [],
    "asfcbc150",
    "M21 0.166672C9.51249 0.166672 0.166656 9.5125 0.166656 21C0.166656 32.4875 9.51249 41.8333 21 41.8333C32.4875 41.8333 41.8333 32.4875 41.8333 21C41.8333 9.5125 32.4875 0.166672 21 0.166672ZM29.9177 22.1052L23.6677 28.3552C23.3625 28.6604 22.9625 28.8125 22.5625 28.8125C22.1625 28.8125 21.7625 28.6604 21.4573 28.3552C20.8469 27.7458 20.8469 26.7552 21.4573 26.1458L25.0396 22.5635H13.1875C12.3239 22.5635 11.625 21.8646 11.625 21.001C11.625 20.1375 12.3239 19.4385 13.1875 19.4385H25.0406L21.4583 15.8563C20.8479 15.2469 20.8479 14.2563 21.4583 13.6469C22.0687 13.0365 23.0573 13.0365 23.6677 13.6469L29.9177 19.8969C30.5271 20.5052 30.5271 21.4948 29.9177 22.1052Z"
  ]
};
