export default {
  prefix: "fapzse",
  iconName: "filter-printer",
  icon: [
    25,
    25,
    [],
    "asfcbc111",
    "M 8.59375 2.605469 C 7.308594 2.605469 6.25 3.664062 6.25 4.949219 L 6.25 5.730469 L 5.46875 5.730469 C 3.601562 5.730469 2.082031 7.246094 2.082031 9.113281 L 2.082031 16.40625 C 2.082031 17.699219 3.136719 18.75 4.425781 18.75 L 6.25 18.75 L 6.25 20.050781 C 6.25 21.335938 7.308594 22.394531 8.59375 22.394531 L 16.40625 22.394531 C 17.691406 22.394531 18.75 21.335938 18.75 20.050781 L 18.75 18.75 L 20.574219 18.75 C 21.863281 18.75 22.917969 17.699219 22.917969 16.40625 L 22.917969 9.113281 C 22.917969 7.246094 21.398438 5.730469 19.53125 5.730469 L 18.75 5.730469 L 18.75 4.949219 C 18.75 3.664062 17.691406 2.605469 16.40625 2.605469 Z M 8.59375 4.167969 L 16.40625 4.167969 C 16.847656 4.167969 17.1875 4.507812 17.1875 4.949219 L 17.1875 5.730469 L 7.8125 5.730469 L 7.8125 4.949219 C 7.8125 4.507812 8.152344 4.167969 8.59375 4.167969 Z M 8.59375 14.582031 L 16.40625 14.582031 C 16.847656 14.582031 17.1875 14.921875 17.1875 15.363281 L 17.1875 20.050781 C 17.1875 20.492188 16.847656 20.832031 16.40625 20.832031 L 8.59375 20.832031 C 8.152344 20.832031 7.8125 20.492188 7.8125 20.050781 L 7.8125 15.363281 C 7.8125 14.921875 8.152344 14.582031 8.59375 14.582031 Z M 8.59375 14.582031"
  ]
};
