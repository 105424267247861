export default {
  prefix: "fapzse",
  iconName: "filter-search",
  icon: [
    25,
    25,
    [],
    "asfcbc112",
    "M 9.894531 14.582031 L 9.894531 21.09375 C 9.894531 21.386719 10.058594 21.65625 10.320312 21.789062 C 10.433594 21.847656 10.554688 21.875 10.675781 21.875 C 10.835938 21.875 10.996094 21.824219 11.132812 21.730469 L 14.777344 19.125 C 14.980469 18.980469 15.105469 18.742188 15.105469 18.488281 L 15.105469 14.582031 Z M 20.574219 3.125 L 4.425781 3.125 C 3.710938 3.125 3.125 3.710938 3.125 4.425781 L 3.125 6.035156 C 3.125 7.242188 3.667969 8.359375 4.613281 9.105469 L 9.585938 13.019531 L 15.414062 13.019531 L 20.386719 9.105469 C 21.332031 8.359375 21.875 7.238281 21.875 6.035156 L 21.875 4.425781 C 21.875 3.710938 21.289062 3.125 20.574219 3.125 Z M 20.574219 3.125"
  ]
};
