export default {
  prefix: "fapzse",
  iconName: "claimed",
  icon: [
    15,
    15,
    [],
    "asfcbc66",
    "M13 6.04042C12.225 6.94136 11.675 7.59204 11.1 8.24272C10.175 9.29382 9.825 9.29382 8.925 8.26775C8.35 7.61707 7.8 6.94136 7.225 6.26565C6.825 6.51592 7.025 6.86628 7.025 7.1666C7 9.39393 7 11.5962 7 13.7985C7 14.4742 6.725 14.9748 6.025 14.9748C5.325 14.9748 5 14.5243 5 13.8236C5 12.9476 5 12.0717 5 11.1958C5 10.5201 4.7 10.0196 4 10.0196C3.3 10.0196 3 10.4951 3 11.1958C3 12.0717 3 12.9476 3 13.8236C3 14.4993 2.7 14.9998 2 14.9748C1.3 14.9748 1 14.4742 1 13.7985C1 13.1729 1 12.5472 1 11.9216C1 11.371 1.05 10.8955 0.45 10.5201C0.025 10.2698 0 9.69424 0 9.16869C0.025 7.61707 0 6.09047 0 4.53885C0 2.71194 0.65 1.68586 2.025 1.18534C3.65 0.609736 5.425 1.01015 6.475 2.23644C7.375 3.31256 8.325 4.38869 9.175 5.51487C9.75 6.26565 10.15 6.44084 10.8 5.56492C11.65 4.38869 12.625 3.28754 13.575 2.18639C14.6 0.985129 16.425 0.609736 18.05 1.21036C19.375 1.71089 20 2.76199 20 4.51382C20 5.89026 20 7.2667 20 8.64314C20 9.46901 20 10.2198 19.225 10.8705C18.65 11.371 19.075 12.297 19 13.0227C18.925 13.8236 19.25 14.9247 18.05 14.9748C16.725 15.0248 17.025 13.8486 17 13.0227C16.975 12.3971 17 11.7714 17 11.1458C17 10.5201 16.675 10.0696 16.05 10.0696C15.35 10.0446 15.025 10.5201 15 11.1958C15 11.8965 15 12.6223 15 13.323C15 14.0989 15.075 14.9748 14.025 14.9998C12.975 15.0248 13 14.1489 13 13.3731C13 11.0206 13 8.69319 13 6.04042Z"
  ]
};
