export default {
  prefix: "fapzse",
  iconName: "arrow-right",
  icon: [
    200,
    110,
    [],
    "asbc79",
    "M143.695 0.230464L129.555 15.5332L161.975 48.4775L0.264623 48.4775L0.264622 67.7744L161.975 67.7744L129.555 97.4082L143.695 110.23L200.265 57.7207L143.695 0.230464Z"
  ]
};
