import _ from "lodash";
import store from "@/store";
import patch_notes from "@/views/PatchNotes/Notes";
import config from "@/config";
import cookies from "js-cookie";

export default function({ next }) {
  let notes = _.cloneDeep(patch_notes);
  let notes_count = notes.length;
  let filtered_notes;

  /** sort by date */
  notes.sort(function compare(a, b) {
    var dateA = new Date(a.date);
    var dateB = new Date(b.date);
    return dateB - dateA;
  });

  /** filter by environment */
  filtered_notes = notes.filter(patch => {
    return !patch.env_restriction?.includes(config.app_env_mode);
  });

  store.commit("SET_PATCH_NOTES", filtered_notes);

  /** check if already shown in current session */
  const patch_shown = cookies.get("patch")
    ? JSON.parse(cookies.get("patch"))
    : false;

  /** retrieve patch count to check if there is movement in patch notes */
  const patch_count = cookies.get("patch_count")
    ? JSON.parse(cookies.get("patch_count"))
    : false;

  /** remove and set new count if there is new patch notes */
  if (patch_count !== notes_count) {
    cookies.remove("patch_noshow");
    cookies.set("patch_count", JSON.stringify(notes_count), {
      expires: 300
    });
  }

  /** check for do not show again checkbox */
  const patch_noshow = cookies.get("patch_noshow")
    ? JSON.parse(cookies.get("patch_noshow"))
    : false;

  /** show accordingly */
  if (!patch_noshow && !patch_shown && filtered_notes.length > 0) {
    store.commit("SET_PATCH_NOTES_DIALOG", true);
  }

  return next();
}
