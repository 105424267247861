export default {
  prefix: "fapzse",
  iconName: "eye",
  icon: [
    20,
    20,
    [],
    "asfcbc128",
    "M9.9944 0C5.2779 0 1.12213 3.26913 0.0196353 7.5529C-0.021589 7.71348 0.00266548 7.88386 0.0870629 8.02656C0.17146 8.16925 0.309087 8.27258 0.469668 8.3138C0.630248 8.35503 0.800628 8.33077 0.943325 8.24637C1.08602 8.16198 1.18935 8.02435 1.23057 7.86377C2.18057 4.17253 5.86092 1.25 9.9944 1.25C14.1279 1.25 17.8199 4.17356 18.7696 7.86377C18.8109 8.02435 18.9142 8.16198 19.0569 8.24637C19.1996 8.33077 19.37 8.35503 19.5305 8.3138C19.6911 8.27258 19.8287 8.16925 19.9131 8.02656C19.9975 7.88386 20.0218 7.71348 19.9806 7.5529C18.8778 3.2681 14.7109 0 9.9944 0ZM10.0009 3.33333C7.78422 3.33333 5.97422 5.14333 5.97422 7.36003C5.97422 9.57672 7.78422 11.3875 10.0009 11.3875C12.2176 11.3875 14.0284 9.57672 14.0284 7.36003C14.0284 5.14333 12.2176 3.33333 10.0009 3.33333Z"
  ]
};
