export default {
  prefix: "fapzse",
  iconName: "filter-archived",
  icon: [
    25,
    25,
    [],
    "asfcbc114",
    "M 6.25 2.082031 C 5.097656 2.082031 4.167969 3.015625 4.167969 4.167969 L 4.167969 20.832031 C 4.167969 21.984375 5.097656 22.917969 6.25 22.917969 L 18.75 22.917969 C 19.902344 22.917969 20.832031 21.984375 20.832031 20.832031 L 20.832031 4.167969 C 20.832031 3.015625 19.902344 2.082031 18.75 2.082031 L 14.582031 2.082031 C 14.007812 2.082031 13.542969 2.550781 13.542969 3.125 C 13.542969 3.699219 13.074219 4.167969 12.5 4.167969 C 11.925781 4.167969 11.457031 3.699219 11.457031 3.125 C 11.457031 2.550781 10.992188 2.082031 10.417969 2.082031 Z M 12.5 5.207031 C 13.074219 5.207031 13.542969 5.675781 13.542969 6.25 C 13.542969 6.824219 13.074219 7.292969 12.5 7.292969 C 11.925781 7.292969 11.457031 6.824219 11.457031 6.25 C 11.457031 5.675781 11.925781 5.207031 12.5 5.207031 Z M 12.5 8.332031 C 13.074219 8.332031 13.542969 8.800781 13.542969 9.375 C 13.542969 9.949219 13.074219 10.417969 12.5 10.417969 C 11.925781 10.417969 11.457031 9.949219 11.457031 9.375 C 11.457031 8.800781 11.925781 8.332031 12.5 8.332031 Z M 11.957031 11.457031 L 13.039062 11.457031 C 13.359375 11.457031 13.667969 11.605469 13.863281 11.859375 C 14.449219 12.625 15.625 14.328125 15.625 15.625 C 15.625 17.351562 14.226562 18.75 12.5 18.75 C 10.773438 18.75 9.375 17.351562 9.375 15.625 C 9.375 14.328125 10.550781 12.625 11.136719 11.859375 C 11.332031 11.605469 11.636719 11.457031 11.957031 11.457031 Z M 12.5 14.582031 C 11.925781 14.582031 11.457031 15.050781 11.457031 15.625 C 11.457031 16.199219 11.925781 16.667969 12.5 16.667969 C 13.074219 16.667969 13.542969 16.199219 13.542969 15.625 C 13.542969 15.050781 13.074219 14.582031 12.5 14.582031 Z M 12.5 14.582031"
  ]
};
