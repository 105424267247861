export default {
  prefix: "fapzse",
  iconName: "filter-delete",
  icon: [
    80,
    80,
    [],
    "asfcbc120",
    "M80 40C80 17.9116 62.0884 0 40 0C17.9116 0 0 17.9116 0 40C0 62.0884 17.9116 80 40 80C62.0884 80 80 62.0884 80 40Z" +
      "M44.6747 39.9998L56.3638 26.4238C57.6385 24.94 57.4773 22.7156 55.9935 21.4257C54.5097 20.1535 52.2852 20.3147 51.0105 21.796L39.9991 34.5835L29.0027 21.8137C27.7305 20.3299 25.4884 20.1686 24.0046 21.4434C22.5208 22.7156 22.3595 24.9576 23.6343 26.4415L35.3234 39.9998L23.6343 53.5759C22.3595 55.0597 22.5208 57.2841 24.0046 58.574C24.6671 59.1534 25.4884 59.428 26.3097 59.428C27.3098 59.428 28.2948 59.0098 28.9876 58.2036L39.9839 45.4338L50.9778 58.2036C51.6731 59.0098 52.6707 59.428 53.6557 59.428C54.4769 59.428 55.3007 59.1534 55.9608 58.574C57.4446 57.2993 57.6058 55.0597 56.3311 53.5759L44.6747 39.9998Z"
  ]
};
