export default {
  prefix: "fapzse",
  iconName: "comment-icon-2",
  icon: [
    25,
    25,
    [],
    "asfcbc126",
    "M10.0192 7.61743e-05C14.0211 7.61743e-05 17.6979 2.47197 19.2237 6.16732C20.7744 9.88763 19.974 14.2072 17.0726 17.0037C14.0211 19.95 10.4194 20.7739 6.39244 19.2509C5.6921 18.9762 5.0668 18.9263 4.36646 19.1759C3.51605 19.4756 2.61561 19.7003 1.71517 19.925C0.589629 20.1747 -0.160735 19.4256 0.0643744 18.302C0.114399 18.1023 0.164423 17.9025 0.239459 17.7028C0.814738 15.93 0.914787 14.2821 0.36452 12.3346C-1.41134 6.09241 3.54106 -0.0248924 10.0192 7.61743e-05Z" +
      "M9.74524 18.4768C9.09492 18.5018 8.26952 18.4768 7.64422 18.1273C5.94339 17.1285 4.31761 17.4781 2.6418 18.0773C1.84141 18.3769 1.51625 18.2771 1.86642 17.3532C2.06652 16.8539 2.11654 16.3045 2.29163 15.8052C2.61678 14.9562 2.56676 14.1822 2.19158 13.3083C0.640825 9.81272 1.66632 5.81774 4.61775 3.42076C7.51916 1.07371 11.6211 0.873965 14.7226 2.97133C17.8242 5.01875 19.2248 8.91385 18.1243 12.4594C16.9987 16.0798 13.6471 18.5268 9.74524 18.4768Z" +
      "M9.86968 7.49061C8.46901 7.49061 7.04332 7.49061 5.64264 7.49061C5.11738 7.49061 4.56712 7.44067 4.5421 6.79149C4.49208 6.04243 5.06736 5.99249 5.66765 5.99249C8.56905 5.99249 11.4705 5.99249 14.3969 5.99249C14.9221 5.99249 15.4724 6.04243 15.5224 6.69161C15.5724 7.44067 14.9972 7.51558 14.3969 7.49061C12.8461 7.49061 11.3454 7.49061 9.86968 7.49061Z" +
      "M9.99407 10.7366C8.54336 10.7366 7.09266 10.7366 5.64196 10.7366C5.06668 10.7366 4.4914 10.6617 4.51641 9.91262C4.54142 9.26344 5.09169 9.2135 5.64196 9.2135C8.54336 9.2135 11.4448 9.2135 14.3712 9.2135C14.9465 9.2135 15.5217 9.28841 15.4967 10.0125C15.4717 10.6617 14.9215 10.7116 14.3712 10.7116C12.8955 10.7366 11.4448 10.7366 9.99407 10.7366Z" +
      "M7.56807 12.4844C8.21839 12.4844 8.89372 12.4844 9.54403 12.4844C10.0693 12.4844 10.4695 12.6841 10.4445 13.2584C10.4445 13.7578 10.0943 13.9825 9.61907 13.9825C8.16836 13.9825 6.71766 13.9825 5.26696 13.9825C4.79173 13.9825 4.46657 13.7078 4.49158 13.2085C4.5166 12.7091 4.84175 12.4844 5.31698 12.4844C6.09236 12.4844 6.81771 12.4844 7.56807 12.4844Z"
  ]
};
