import _get from "lodash/get";
import _merge from "lodash/merge";
import api from "@/api";
import store from "@/store";
import {
  httpSuccessResponseLogger,
  httpErrorResponseLogger
} from "@/helpers/logger";
import { redirectToProperErrorPage } from "@/helpers/router";

export default function({ next, to, router }) {
  let url = "/access-controls/pages";

  switch (to.name) {
    case "Projects":
      url += "/projects";
      break;

    case "CreateProject":
      url += "/create-project";
      break;

    case "ProjectOverview":
      url += "/project-overview";
      break;

    case "ProjectsArchived":
      url += "/project-archive";
      break;

    default:
      break;
  }

  api
    .get(url)
    .then(response => {
      httpSuccessResponseLogger(response);
      const initialized_permissions = _get(
        response,
        "data.result.permissions",
        {}
      );
      const permissions = _merge(
        store.state.auth.permissions,
        initialized_permissions
      );

      store.commit("auth/SET_PERMISSIONS", permissions);

      return next();
    })
    .catch(error => {
      httpErrorResponseLogger(error);

      redirectToProperErrorPage(error, to, router);
    });
}
