/**
 * Initializes NProgress after a specified delay only if the route was not resolved yet.
 */
import NProgress from "nprogress";

export default function initProgress(router) {
  router.beforeResolve((to, from, next) => {
    if (to.name) {
      NProgress.start(800);
    }

    next();
  });

  router.afterEach(() => NProgress.done());
}
