export default {
  prefix: "fapzse",
  iconName: "warning",
  icon: [
    20,
    20,
    [],
    "asfcbc54",
    "M24.5006 20.1813C21.4078 14.0892 18.2857 8.01389 15.1343 1.95547C13.7726 -0.65351 11.2244 -0.650139 9.86891 1.95547C6.71759 8.02288 3.59549 14.0993 0.502599 20.1847C-0.784016 22.7229 0.502599 24.9644 3.18853 24.988C6.26576 25.015 9.34299 24.988 12.4202 24.988C15.5507 24.988 18.6811 25.0082 21.8116 24.988C24.4975 24.9611 25.781 22.706 24.5006 20.1813ZM12.4609 7.50715C13.1747 7.48693 13.4376 8.02288 13.4408 8.73074C13.4408 9.73973 13.4408 10.751 13.4408 11.7644C13.4408 12.8296 13.4408 13.8948 13.4408 14.9599C13.4408 15.6678 13.1809 16.2071 12.4672 16.1903C11.8411 16.1903 11.5719 15.6914 11.5719 15.0476C11.5719 12.9173 11.5719 10.7824 11.5719 8.6431C11.5813 8.0094 11.8474 7.524 12.4609 7.50715ZM12.5955 20.9532C11.8567 20.9094 11.3434 20.5217 11.2776 19.7599C11.2463 19.5781 11.2526 19.3912 11.2961 19.2123C11.3396 19.0335 11.4192 18.8671 11.5292 18.7252C11.6392 18.5832 11.7769 18.4691 11.9326 18.391C12.0882 18.313 12.2579 18.2728 12.4296 18.2734C12.587 18.2498 12.7471 18.2618 12.8999 18.3087C13.0527 18.3557 13.1946 18.4365 13.3167 18.546C13.4387 18.6555 13.5381 18.7913 13.6086 18.9446C13.679 19.098 13.7189 19.2654 13.7256 19.4363C13.8258 20.3026 13.3249 20.7644 12.5955 20.9532Z"
  ]
};
