export default {
  prefix: "fapzse",
  iconName: "paperclip",
  icon: [
    11,
    20,
    [],
    "asfcbc97",
    "M1.8061 18.6431C0.598996 17.4912 0.026335 16.2901 0.0192065 14.8877C0.00257318 11.0853 -0.0140601 7.28292 0.0192065 3.48054C0.038216 1.45186 1.64452 -0.0221158 3.68328 0.000251145C5.68641 0.0226181 7.24756 1.47423 7.26895 3.44699C7.30697 6.35469 7.26895 9.26239 7.28083 12.1701C7.28083 12.7785 7.17628 13.3421 6.38263 13.3645C5.52007 13.3891 5.38226 12.8075 5.38226 12.1477C5.38226 9.35186 5.38226 6.55599 5.38226 3.76013C5.38226 2.92137 5.18266 2.19445 4.22505 1.89026C3.10112 1.52567 1.9558 2.14971 1.93916 3.30161C1.88214 7.2516 1.85124 11.2038 1.95342 15.1561C1.99857 16.9298 3.7118 18.227 5.56522 18.1868C7.53033 18.1443 9.0392 16.7441 9.06534 14.7937C9.11049 11.1792 9.06534 7.5625 9.09148 3.94577C9.09148 3.23003 8.74218 2.15642 10.0134 2.13405C11.2847 2.11169 10.9639 3.20096 10.9639 3.92341C10.9901 7.57592 11.0209 11.2307 10.9639 14.8832C10.9188 17.1378 9.71166 18.7885 7.46617 19.6093C5.30859 20.4101 3.35775 19.9269 1.8061 18.6431Z"
  ]
};
