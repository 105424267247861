export default {
  prefix: "fapzse",
  iconName: "status-continue",
  icon: [
    18,
    18,
    [],
    "asfcbc104",
    "M10 0C4.48633 0 0 4.48633 0 10C0 15.5137 4.48633 20 10 20C15.5137 20 20 15.5137 20 10C20 4.48633 15.5137 0 10 0ZM14.2803 8.28027L9.28027 13.2803C9.13379 13.4268 8.94238 13.5 8.75 13.5C8.55762 13.5 8.36621 13.4268 8.21973 13.2803L5.71973 10.7803C5.42676 10.4873 5.42676 10.0127 5.71973 9.71973C6.0127 9.42676 6.4873 9.42676 6.78027 9.71973L8.74902 11.6895L13.2188 7.21973C13.5117 6.92676 13.9863 6.92676 14.2793 7.21973C14.5723 7.51269 14.5732 7.9873 14.2803 8.28027Z"
  ]
};
