export default {
  prefix: "fapzse",
  iconName: "full-structural",
  icon: [
    19,
    19,
    [],
    "asfcbc66",
    "M9.59519 18.991C7.13418 18.991 4.66976 18.991 2.20195 18.991C0.557586 18.991 0.402073 18.82 0.399524 17.0803C0.399524 14.4484 0.427567 11.8165 0.379129 9.18464C0.376579 8.50036 0.085948 7.81607 0.001818 7.10546C-0.0141562 6.77776 0.0754949 6.45383 0.256758 6.1843C1.41928 4.45779 2.55121 2.70233 3.82591 1.07846C4.3253 0.507695 5.016 0.153993 5.75835 0.0888697C8.30775 -0.0243011 10.8571 -0.0348286 13.3912 0.0888697C14.1687 0.174849 14.8865 0.559006 15.4027 1.16531C16.6478 2.77095 17.8144 4.43989 18.8979 6.16588C19.084 6.45275 18.9642 6.9765 18.9362 7.38707C18.9081 7.79764 18.773 8.13452 18.773 8.50825C18.7577 11.4428 18.7322 14.38 18.773 17.3119C18.7934 18.5726 18.2886 19.0252 17.1006 18.9989C14.6048 18.9489 12.0987 18.991 9.59519 18.991ZM16.6341 16.8224V7.41076C14.7654 7.41076 12.9859 7.36865 11.209 7.42655C10.6998 7.46781 10.1897 7.36096 9.73637 7.11814C9.28306 6.87531 8.90472 6.50622 8.64427 6.05271C7.83101 4.7973 6.94637 3.5919 6.01839 2.25754C4.86606 3.92352 3.82591 5.4079 2.80615 6.90807C2.62137 7.14364 2.50473 7.42826 2.46963 7.72922C2.44669 10.7427 2.45434 13.7536 2.45434 16.8066H4.49385C4.49385 15.159 4.50915 13.5878 4.49385 12.0192C4.49385 11.0401 4.88901 10.5401 5.86033 10.569C6.24163 10.5896 6.62371 10.5896 7.00501 10.569C8.15733 10.4795 8.62387 11.0243 8.58818 12.2008C8.54229 13.722 8.58818 15.2432 8.58818 16.825L16.6341 16.8224Z" +
      "M12.8301 10.5691C14.5816 10.5691 14.6886 10.7033 14.6835 12.8009C14.6835 14.6985 14.5765 14.788 12.4605 14.7801C10.6759 14.7801 10.5918 14.659 10.6045 12.4114C10.6147 10.6717 10.7498 10.5612 12.8301 10.5691Z"
  ]
};
