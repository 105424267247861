export default {
  prefix: "fapzse",
  iconName: "legacy",
  icon: [
    16,
    16,
    [],
    "legacy1",
    "M13.1869 4.4L10.0999 1.2248C9.83856 0.95438 9.48361 0.801611 9.1129 0.800003H3.7999C3.4286 0.800003 3.0725 0.951717 2.80995 1.22177C2.5474 1.49182 2.3999 1.85809 2.3999 2.24V13.76C2.3999 14.1419 2.5474 14.5082 2.80995 14.7782C3.0725 15.0483 3.4286 15.2 3.7999 15.2H12.1999C12.5712 15.2 12.9273 15.0483 13.1899 14.7782C13.4524 14.5082 13.5999 14.1419 13.5999 13.76V5.4152C13.5983 5.03391 13.4498 4.66882 13.1869 4.4Z" +
      "M6.55383 11.2H7.39259L9.12096 7.2H8.27584L7.27186 9.50369C7.1829 9.70615 7.08759 9.95239 6.97321 10.3026C6.87154 9.97975 6.78258 9.74446 6.68092 9.50369L5.66423 7.2H4.80005L6.55383 11.2Z" +
      "M10.4185 7.82927V11.2H11.2V7.2H9.40179V7.82927H10.4185Z"
  ]
};
