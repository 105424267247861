export default {
  prefix: "fapzse",
  iconName: "progress-four-circle",
  icon: [
    18,
    18,
    [],
    "ljfcbc103",
    "M9.5332 5.80375e-05C7.65419 -0.00650857 5.81544 0.544291 4.24949 1.5828C2.68353 2.62131 1.46072 4.10087 0.735689 5.83438C0.0106604 7.56788 -0.184013 9.47747 0.176287 11.3216C0.536588 13.1658 1.43568 14.8617 2.75985 16.1948C4.08403 17.5279 5.77381 18.4385 7.61548 18.8112C9.45716 19.1839 11.368 19.0022 13.1064 18.2889C14.8447 17.5755 16.3325 16.3627 17.3816 14.8038C18.4306 13.2449 18.9938 11.4099 19 9.53093C19.0082 7.01178 18.0154 4.59252 16.2402 2.8052C14.4649 1.01788 12.0523 0.00886176 9.5332 5.80375e-05ZM12.35 9.51906C12.35 10.7422 12.35 11.9653 12.35 13.1908C12.35 13.7062 12.2668 14.2026 11.6375 14.2121C11.0081 14.2216 10.9487 13.7371 10.9012 13.2146C10.83 12.4546 11.3073 11.4404 10.6637 11.0034C10.1483 10.6543 9.21258 10.9227 8.46208 10.9203C6.64995 10.9251 6.64995 10.9251 6.64995 9.09393C6.64995 7.98956 6.64995 6.88281 6.64995 5.76893C6.64995 5.24643 6.75445 4.76193 7.37433 4.77143C7.9942 4.78093 8.08683 5.27493 8.08683 5.79268C8.08683 6.38406 8.08683 6.98018 8.08683 7.56918C8.07495 9.50006 8.64495 9.92756 10.5426 9.50006C11.0485 9.38843 10.9131 9.07731 10.9178 8.79943C10.9321 7.77343 10.9178 6.74743 10.9178 5.71193C10.9178 5.17756 11.0698 4.72393 11.6897 4.76193C12.2241 4.79518 12.35 5.23693 12.3476 5.71193C12.35 6.99443 12.35 8.25556 12.35 9.51906Z"
  ]
};
