export default {
  prefix: "fapzse",
  iconName: "download",
  icon: [
    16,
    16,
    [],
    "asfcbf82",
    "M9.99609 9.70764C9.89007 9.70764 9.80526 9.78774 9.79348 9.89374C9.70161 10.8031 8.93362 11.5122 7.99835 11.5122C7.06544 11.5122 6.29743 10.8031 6.2032 9.89374C6.19142 9.79014 6.10661 9.70764 6.0006 9.70764H3.55054C3.03697 9.70764 2.62 10.1246 2.62 10.6382V12.5888C2.62 13.1023 3.03697 13.5193 3.55054 13.5193H12.4509C12.9645 13.5193 13.3815 13.1023 13.3815 12.5888V10.6405C13.3815 10.1269 12.9645 9.71004 12.4509 9.71004H9.99609V9.70764Z" +
      "M9.39067 6.67867L8.66272 7.40662V2.66199C8.66272 2.29683 8.36588 2 8.00073 2C7.63558 2 7.33874 2.29683 7.33874 2.66199V7.40662L6.61079 6.67867C6.38699 6.45487 6.0289 6.39833 5.76505 6.57502C5.40461 6.81531 5.36927 7.30768 5.65904 7.59745L7.60966 9.54804C7.8264 9.76484 8.17742 9.76484 8.39415 9.54804L10.3448 7.59745C10.6346 7.30768 10.5992 6.81531 10.2388 6.57502C9.97256 6.39597 9.61447 6.45251 9.39067 6.67867Z"
  ]
};
