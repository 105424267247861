export default {
  prefix: "fapzse",
  iconName: "hold",
  icon: [
    21,
    26,
    [],
    "asfcbc24",
    "M20.9676 12.4128C20.9676 14.4647 20.993 16.5166 20.9676 18.5654C20.9104 22.1705 18.5684 25.1947 15.0094 25.6713C11.9334 26.088 8.77153 26.1827 5.69551 25.5135C2.17461 24.7432 0.102746 22.0979 0.0169478 18.4991C-0.0211847 16.9207 0.0169478 15.3423 0.0169478 13.7639C-0.00529618 12.719 0.499959 11.9961 1.58356 11.9961C2.66716 11.9961 3.17241 12.7064 3.17241 13.7544C3.17241 14.5941 3.17241 15.437 3.17241 16.2799C3.17241 16.8165 2.97857 17.6152 3.8588 17.5742C4.58331 17.5426 4.42761 16.8197 4.43078 16.3114C4.43078 15.1024 4.43078 13.8933 4.43078 12.6843C4.43078 9.63481 4.44985 6.58534 4.43078 3.52956C4.44349 2.3868 4.60238 1.29771 6.00057 1.26298C7.39876 1.22826 7.67205 2.31104 7.6212 3.47274C7.6212 3.8926 7.6212 4.31245 7.6212 4.73546C7.6212 6.94522 7.58625 9.15498 7.64345 11.3647C7.65934 11.9961 7.1795 13.1262 8.29805 13.1199C9.25137 13.1199 8.86686 12.0466 8.8764 11.4405C8.91453 8.49521 8.89229 5.55307 8.89229 2.60146C8.89229 2.28579 8.89229 1.97011 8.89229 1.65443C8.97491 0.704229 9.45156 0.000263554 10.4494 0.000263554C11.5298 -0.0155204 12.0382 0.678975 12.0732 1.73019C12.0732 2.30788 12.0732 2.88558 12.0732 3.46643C12.0732 6.25388 12.0732 9.04133 12.0732 11.8256C12.0732 12.3623 11.8698 13.1767 12.7246 13.1515C13.5794 13.1262 13.3411 12.3118 13.3411 11.7751C13.358 8.98872 13.358 6.20232 13.3411 3.41592C13.3411 2.30473 13.5921 1.31033 14.8759 1.2693C16.2741 1.2251 16.5442 2.25106 16.5347 3.43486C16.5124 6.48433 16.5347 9.53379 16.5347 12.5896C16.5347 13.0947 16.4076 13.7923 17.1702 13.7986C17.9329 13.8049 17.8058 13.1104 17.8058 12.6022C17.8058 10.3924 17.8248 8.18268 17.8058 5.97293C17.8058 4.79228 18.0886 3.76317 19.4741 3.80105C20.7451 3.83577 21.0152 4.83016 20.9994 5.94767C20.9676 8.10061 20.9676 10.2567 20.9676 12.4128Z"
  ]
};
