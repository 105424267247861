export default {
  prefix: "fapzse",
  iconName: "suspend",
  icon: [
    8,
    8,
    [],
    "asfc104",
    "M4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8C6.2091 8 8 6.20914 8 4C7.9976 1.79184 6.2082 0.00239062 4 0ZM4 1C4.62255 1.00006 5.22947 1.19495 5.73567 1.55733L1.55733 5.73533C0.59634 4.3888 0.90891 2.51819 2.25544 1.5572C2.76453 1.19389 3.37456 0.999047 4 1ZM4 7C3.37755 6.99997 2.77073 6.80508 2.26467 6.44267L6.4427 2.26434C7.4038 3.61075 7.0915 5.48139 5.74512 6.44256C5.23591 6.80606 4.62566 7.001 4 7Z"
  ]
};
