export default {
  prefix: "fapzse",
  iconName: "anchorage-cutsheet",
  icon: [
    24,
    24,
    [],
    "asfc15",
    "M23.9012 22.7566L20.3601 19.2372V5.69777L18.6876 2.62195C18.5642 2.38744 18.3792 2.1911 18.1523 2.05413C17.9255 1.91716 17.6656 1.84476 17.4006 1.84476C17.1356 1.84476 16.8757 1.91716 16.6488 2.05413C16.422 2.1911 16.2369 2.38744 16.1135 2.62195L14.5429 5.70504V13.4491L1.24343 0.215101C1.14172 0.112552 1.01178 0.042615 0.870172 0.0142057C0.728562 -0.0142036 0.5817 0.00020223 0.44831 0.0555865C0.31492 0.110971 0.201045 0.204824 0.121204 0.325181C0.0413625 0.445537 -0.00082762 0.586944 1.23011e-05 0.731373V6.6067H1.57792V7.77013H1.23011e-05V12.4239H1.57792V13.5873H1.23011e-05V18.4228H1.57792V19.5862H1.23011e-05V23.2728C1.23011e-05 23.4657 0.0766219 23.6506 0.212988 23.787C0.349354 23.9234 0.534306 24 0.727157 24H23.385C23.5294 24.0008 23.6708 23.9586 23.7912 23.8788C23.9115 23.799 24.0054 23.6851 24.0608 23.5517C24.1161 23.4183 24.1305 23.2714 24.1021 23.1298C24.0737 22.9882 24.0038 22.8583 23.9012 22.7566ZM5.81717 18.241V11.6531L12.4124 18.241H5.81717ZM18.9058 19.7753H15.9972V18.0083H18.9058V19.7753ZM18.9058 16.8667H15.9972V6.06134L17.4078 3.32001L18.9058 6.06861V16.8667Z"
  ]
};
