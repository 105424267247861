export default {
  prefix: "fapzse",
  iconName: "wet-stamp",
  icon: [
    20,
    20,
    [],
    "asfcbc82",
    "M9.98859 16.5826C7.34216 16.5826 4.68324 16.5826 2.02683 16.5826C1.11306 16.5826 0.229252 16.5826 0.0295212 15.4452C-0.145243 14.4372 0.468928 13.2999 1.5275 13.0558C3.77254 12.4938 6.04694 12.0506 8.34081 11.7281C11.7337 11.3107 15.0018 12.2724 18.2774 12.9704C19.301 13.19 19.8902 13.9271 19.9876 14.9742C20.0875 16.0676 19.5881 16.5679 18.3498 16.5753C15.5611 16.5826 12.7798 16.5826 9.98859 16.5826Z" +
      "M10.2707 10.725C10.0337 10.7107 9.79612 10.7107 9.55916 10.725C8.46064 10.9007 7.84897 10.6029 7.70167 9.37773C7.57684 8.32092 6.73048 7.52526 6.17623 6.62709C5.67974 5.84772 5.45097 4.93366 5.52328 4.01823C5.59558 3.10281 5.96519 2.23392 6.57818 1.5383C7.18729 0.841856 8.0039 0.349028 8.91372 0.128791C9.82354 -0.0914462 10.781 -0.0280694 11.6522 0.310051C12.5233 0.648171 13.2645 1.24411 13.772 2.01446C14.2796 2.78481 14.528 3.69101 14.4825 4.60622C14.4211 5.58394 14.0335 6.51501 13.379 7.25678C12.8972 7.8279 12.3804 8.45027 12.3254 9.15563C12.2106 10.6762 11.4117 11.0276 10.2707 10.725Z" +
      "M10.0061 17.5588C12.5027 17.5588 14.9993 17.5588 17.4959 17.5588C18.5321 17.5588 18.7767 17.9152 18.3723 18.867C18.245 19.2147 18.006 19.5128 17.6909 19.7165C17.3758 19.9202 17.0017 20.0186 16.6246 19.9971C12.2139 19.9971 7.80319 19.9971 3.39247 19.9971C3.05848 20.0153 2.72615 19.9394 2.43495 19.7785C2.14375 19.6176 1.90591 19.3783 1.74969 19.0891C1.16548 18.086 1.47506 17.5588 2.64099 17.5588H10.0061Z"
  ]
};
