export default {
  prefix: "fapzse",
  iconName: "warning-circle",
  icon: [
    20,
    20,
    [],
    "asfcbc99",
    "M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM8.74 4H10.85V11.94H8.74V4ZM9.81001 16C9.64473 16.0078 9.4797 15.9801 9.32601 15.9188C9.17231 15.8575 9.0335 15.7641 8.9189 15.6448C8.80429 15.5254 8.71655 15.3829 8.66154 15.2269C8.60654 15.0708 8.58556 14.9048 8.60001 14.74C8.6046 14.5824 8.64019 14.4273 8.70474 14.2834C8.7693 14.1396 8.86154 14.0099 8.97622 13.9017C9.09091 13.7935 9.22577 13.709 9.37312 13.653C9.52047 13.5969 9.67742 13.5704 9.835 13.575C9.99259 13.5796 10.1477 13.6152 10.2916 13.6797C10.4354 13.7443 10.5651 13.8365 10.6733 13.9512C10.7815 14.0659 10.866 14.2008 10.9221 14.3481C10.9781 14.4955 11.0046 14.6524 11 14.81C11.0056 14.9678 10.9786 15.125 10.9208 15.2719C10.863 15.4188 10.7755 15.5523 10.6639 15.6639C10.5523 15.7755 10.4188 15.863 10.2719 15.9208C10.125 15.9786 9.96778 16.0056 9.81001 16Z"
  ]
};
