export default {
  prefix: "fapzse",
  iconName: "hold-circle",
  icon: [
    20,
    20,
    [],
    "asfcbc24",
    "M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM15 10.21C15 11.21 15 12.21 15 13.28C15.0257 14.1386 14.7338 14.9765 14.1803 15.6333C13.6268 16.2901 12.8505 16.7198 12 16.84C10.4568 17.0783 8.88414 17.0513 7.35 16.76C6.5357 16.61 5.80188 16.1738 5.28106 15.5301C4.76024 14.8864 4.48676 14.0777 4.51 13.25C4.51 12.46 4.51 11.67 4.51 10.88C4.51 10.36 4.75 10 5.29 10C5.83 10 6.09 10.35 6.09 10.88V12.14C6.09 12.41 5.99 12.81 6.43 12.79C6.87 12.77 6.71 12.41 6.72 12.16V5.76C6.72 5.19 6.8 4.65 7.5 4.63C8.2 4.61 8.34 5.16 8.31001 5.74V6.37C8.31001 7.47 8.31001 8.58 8.31001 9.68C8.31001 10 8.08 10.56 8.64001 10.56C9.20001 10.56 8.92 10.02 8.93001 9.72C9 8.25 9 6.78 9 5.3V4.83C9 4.35 9.28001 4 9.77001 4C10.26 4 10.57 4.34 10.59 4.87V9.87C10.59 10.14 10.48 10.55 10.91 10.54C11.34 10.53 11.22 10.12 11.22 9.85C11.22 8.45 11.22 7.06 11.22 5.67C11.22 5.11 11.35 4.67 11.99 4.59C12.63 4.51 12.82 5.09 12.82 5.68C12.82 7.2 12.82 8.73 12.82 10.25C12.82 10.51 12.75 10.86 13.14 10.86C13.53 10.86 13.45 10.52 13.45 10.26C13.45 9.16 13.45 8.05 13.45 6.95C13.45 6.36 13.59 5.84 14.29 5.86C14.99 5.88 15 6.42 15 7C15 8.05 15 9.13 15 10.21Z"
  ]
};
