import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import api from "@/api";
import store from "@/store";
import {
  httpSuccessResponseLogger,
  httpErrorResponseLogger,
  Logger
} from "@/helpers/logger";
import { redirectToProperErrorPage } from "@/helpers/router";

export default function({ next, to, router }) {
  Logger("/projects/create/v2");

  api
    .get("/projects/create/v2")
    .then(response => {
      httpSuccessResponseLogger(response);

      const project = _get(response, "data.result", {});
      const progress = _get(project, "project_creation_progress", {});

      const details = _get(progress, "details", {});

      if (!_isEmpty(details)) {
        store.commit("createProject/SET_PROJECT_CREATION_PROGRESS", details);
      }

      const id = _get(progress, "project_id", null);
      store.commit("createProject/SET_ID", id);

      const activity_id = _get(details, "activity_id", null);
      store.commit("createProject/SET_ACTIVITY_ID", activity_id);

      store.commit(
        "createProject/SET_SHIPPING_PROFILE_ID",
        project.creator.shipping_profile_id
      );

      if (project.shipping_profile ?? null) {
        store.commit(
          "createProject/SET_FORMDATA_TYPE_SHIPPING_PROFILE",
          project.shipping_profile
        );
      }

      const summary = _get(project, "summary", null);
      store.commit("createProject/SET_SUMMARY", summary);

      return next();
    })
    .catch(error => {
      httpErrorResponseLogger(error);

      redirectToProperErrorPage(error, to, router);
    });
}
