export default {
  prefix: "fapzse",
  iconName: "resolved",
  icon: [
    24,
    25,
    [],
    "asfcbc123",
    "M15.9889 4.72524C15.9639 2.96181 15.1395 2.11735 13.3907 2.14219C12.5413 2.14219 12.0167 2.01801 11.542 1.17355C10.6676 -0.391183 5.3213 -0.391183 4.44691 1.17355C3.97224 2.01801 3.47259 2.16703 2.62318 2.16703C0.84941 2.11735 0.0249827 2.96181 0 4.72524C0 8.92269 0 13.145 0 17.3673C0 19.1804 0.84941 20 2.69813 20C6.22068 20 9.76822 20 13.2908 20C15.1645 20 15.9889 19.1804 15.9889 17.3673C16.0139 15.2561 15.9889 13.145 15.9889 11.0338C15.9889 8.92269 16.0139 6.83639 15.9889 4.72524ZM6.27065 1.67029C7.41985 1.59578 8.59403 1.59578 9.74323 1.67029C9.99306 1.69513 10.2429 2.11735 10.4677 2.34089C10.1929 2.58926 9.96808 3.01149 9.66829 3.06116C9.11867 3.18535 8.51908 3.086 7.94448 3.086C7.41985 3.086 6.84525 3.18535 6.34559 3.06116C6.0458 2.98665 5.82096 2.58926 5.54615 2.31605C5.77099 2.09252 6.02082 1.69513 6.27065 1.67029ZM7.9195 15.5293C5.49618 15.5045 3.52255 13.5175 3.49757 11.0835C3.47259 8.59981 5.49618 6.58802 7.99445 6.58802C10.4927 6.58802 12.4913 8.62465 12.4913 11.0835C12.4663 13.5672 10.4178 15.5542 7.9195 15.5293Z" +
      "M7.24462 12.2757C8.21894 11.2325 8.9934 10.3632 9.81783 9.56843C10.0427 9.3449 10.4924 9.36973 10.8171 9.27039C10.7172 9.59327 10.7172 9.96582 10.5173 10.1894C9.66794 11.1083 8.76856 11.9279 7.89417 12.8221C7.4195 13.3188 7.04476 13.3685 6.57009 12.8221C6.17036 12.3502 5.67071 11.9776 5.27099 11.4809C5.12109 11.307 5.17106 10.9841 5.12109 10.7358C5.3959 10.7606 5.77064 10.6861 5.94552 10.86C6.39521 11.2325 6.71998 11.6796 7.24462 12.2757Z"
  ]
};
