export default {
  prefix: "fapzse",
  iconName: "ahj-card",
  icon: [
    23,
    18,
    [],
    "asfcbc175",
    "M20.25 0H2.25C1.00688 0 0 1.00688 0 2.25V15.75C0 16.9931 1.00688 18 2.25 18H20.25C21.4931 18 22.5 16.9931 22.5 15.75V2.25C22.5 1.00688 21.4931 0 20.25 0ZM2.25 3.375H6.75V5.625H2.25V3.375ZM13.5 14.625H2.25V12.375H13.5V14.625ZM18 10.125H2.25V7.875H18V10.125Z"
  ]
};
