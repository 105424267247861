export default {
  prefix: "fapzse",
  iconName: "patch-notes",
  icon: [
    24,
    24,
    [],
    "asfz83",
    "M13.2043 20.7905C13.4904 20.857 13.5167 21.2319 13.2379 21.3249L11.5921 21.8665C7.45664 23.1999 5.27956 22.0853 3.93581 17.9499L2.60248 13.8353C1.26914 9.69983 2.37331 7.51233 6.50873 6.179L7.05455 5.99824C7.47421 5.85927 7.8826 6.28016 7.76345 6.70586C7.70438 6.91687 7.64756 7.13709 7.59206 7.36651L6.57123 11.7311C5.42539 16.6374 7.10248 19.3457 12.0087 20.5124L13.2043 20.7905Z" +
      "M17.8856 3.34207L16.1461 2.93582C12.6669 2.1129 10.594 2.78999 9.3752 5.31082C9.0627 5.94624 8.8127 6.71707 8.60436 7.60249L7.58353 11.967C6.5627 16.3212 7.90645 18.467 12.2502 19.4983L14.0002 19.9149C14.6044 20.0608 15.1669 20.1545 15.6877 20.1962C18.9377 20.5087 20.6669 18.9879 21.5419 15.2274L22.5627 10.8733C23.5836 6.51915 22.2502 4.3629 17.8856 3.34207ZM15.9273 13.8837C15.8336 14.2379 15.5211 14.467 15.1669 14.467C15.1044 14.467 15.0419 14.4566 14.969 14.4462L11.9377 13.6754C11.5211 13.5712 11.2711 13.1441 11.3752 12.7274C11.4794 12.3108 11.9065 12.0608 12.3231 12.1649L15.3544 12.9358C15.7815 13.0399 16.0315 13.467 15.9273 13.8837ZM18.9794 10.3629C18.8856 10.717 18.5731 10.9462 18.219 10.9462C18.1565 10.9462 18.094 10.9358 18.0211 10.9254L12.969 9.64415C12.5523 9.53999 12.3023 9.1129 12.4065 8.69624C12.5106 8.27957 12.9377 8.02957 13.3544 8.13374L18.4065 9.41499C18.8336 9.50874 19.0836 9.93582 18.9794 10.3629Z"
  ]
};
