export default {
  prefix: "fapzse",
  iconName: "engineer",
  icon: [
    22,
    25,
    [],
    "asfcb130",
    "M36.0156 0V4.07812C34.3281 4.23438 29.6406 4.84375 24.7188 7.3125C19.3125 10.0312 13.7812 15.75 12.5312 24.5781C9.60938 25.125 7.10938 26.125 5.71875 28.0625C3.79688 30.75 4 33.5469 4 34.9062V44H12.0156C12.0156 53.625 16.9375 62.1562 24.375 67.1875C10.0781 73.2969 0.015625 87.5 0.015625 104H8.01562C8.01562 95.0469 11.6562 86.9844 17.5625 81.1875L27.1562 93.375L40.0156 84.8125L53.125 93.5469L61.7969 80.5625C68.0781 86.3906 72.0156 94.7188 72.0156 104H80.0156C80.0156 87.5 69.9531 73.2969 55.6562 67.1875C63.0938 62.1562 68.0156 53.625 68.0156 44H76V34.9062C76.0156 33.8906 76.5625 31.1094 74.6875 28.2188C73.2969 26.0938 70.6562 25.0781 67.5 24.5469C66.25 15.7031 60.7969 9.96875 55.4375 7.26562C50.2344 4.64062 45.3594 4.14062 44.0156 4.04688V0H36.0156ZM44.0156 12.125C45.1562 12.2344 48.25 12.6094 51.8281 14.4062C55.3438 16.1875 58.5312 18.75 59.6094 24H56.0156V32H64.0156C67.8125 32 68.0781 32.7188 67.9844 32.5781C67.875 32.4375 68.0156 33.0938 68 34.9062V36H12V34.9062C12 33.4531 12.1719 32.7969 12.2188 32.7344C12.2656 32.6719 12.6406 32 16 32H24.0156V24H20.4375C21.5312 18.8281 24.7344 16.25 28.2969 14.4688C31.7656 12.7344 34.6875 12.3125 36.0156 12.1719V20.25H44.0156V12.125ZM20.0156 44H60.0156C60.0156 55.0938 51.1094 64 40.0156 64C28.9219 64 20.0156 55.0938 20.0156 44ZM40.0156 72C45.5625 72 50.7656 73.3906 55.3125 75.8594L50.9062 82.4531L40.0156 75.1875L28.875 82.625L23.9375 76.3438C28.6562 73.5938 34.1406 72 40.0156 72Z"
  ]
};
