export default {
  prefix: "fapzse",
  iconName: "progress-engineering-circle",
  icon: [
    18,
    18,
    [],
    "dafcbc98",
    "M9.55683 0.000170045C7.67796 -0.0110694 5.83794 0.53509 4.26947 1.56958C2.70099 2.60408 1.4745 4.08044 0.745086 5.81199C0.0156729 7.54354 -0.183901 9.45249 0.1716 11.2975C0.527102 13.1424 1.42171 14.8406 2.74231 16.1771C4.0629 17.5136 5.75016 18.4285 7.59073 18.8062C9.4313 19.1838 11.3425 19.0071 13.0827 18.2985C14.8229 17.59 16.3138 16.3813 17.3671 14.8253C18.4203 13.2694 18.9885 11.436 18.9998 9.55717C19.0073 8.3096 18.769 7.07278 18.2985 5.91731C17.828 4.76184 17.1346 3.71036 16.2577 2.82291C15.3808 1.93546 14.3377 1.22941 13.188 0.745082C12.0383 0.260754 10.8044 0.00763297 9.55683 0.000170045ZM11.3001 12.8252C11.8178 12.8252 12.3118 12.9178 12.3118 13.5377C12.3118 14.1575 11.8202 14.2502 11.3001 14.2502C10.0746 14.2502 8.84908 14.2502 7.62596 14.2502C6.91346 14.2502 6.64983 13.92 6.64508 13.2289C6.63083 11.602 7.44783 10.3789 9.11508 9.69967C9.82758 9.40754 10.4997 9.13442 10.7776 8.32454C11.1101 7.37454 10.723 6.46017 9.85133 6.22029C9.02246 5.98279 8.23396 6.51954 8.08908 7.49092C8.00596 8.04667 7.93471 8.61192 7.24833 8.49792C6.50258 8.37442 6.59521 7.69992 6.67833 7.15367C6.80535 6.40429 7.22266 5.7352 7.83976 5.29148C8.45687 4.84777 9.22401 4.66522 9.97483 4.78342C10.3545 4.85472 10.7161 5.00078 11.0388 5.21311C11.3615 5.42544 11.6387 5.69981 11.8544 6.02025C12.0701 6.34069 12.22 6.70082 12.2952 7.07969C12.3705 7.45856 12.3697 7.84862 12.2928 8.22717C12.1845 8.85625 11.9022 9.44241 11.4778 9.91926C11.0534 10.3961 10.504 10.7446 9.89171 10.9252C9.02483 11.2054 8.31233 11.6377 8.15796 12.8252C9.26233 12.8252 10.2765 12.8252 11.3001 12.8252Z"
  ]
};
