export default {
  prefix: "fapzse",
  iconName: "projects",
  icon: [
    25,
    25,
    [],
    "asfcbc103",
    "M8.33337 7.2915H23.4375C23.8519 7.2915 24.2494 7.12688 24.5424 6.83386C24.8354 6.54083 25 6.1434 25 5.729C25 5.3146 24.8354 4.91717 24.5424 4.62415C24.2494 4.33112 23.8519 4.1665 23.4375 4.1665H8.33337C7.91897 4.1665 7.52155 4.33112 7.22852 4.62415C6.93549 4.91717 6.77087 5.3146 6.77087 5.729C6.77087 6.1434 6.93549 6.54083 7.22852 6.83386C7.52155 7.12688 7.91897 7.2915 8.33337 7.2915Z" +
      "M23.4375 11.4585H8.33337C7.91897 11.4585 7.52155 11.6231 7.22852 11.9161C6.93549 12.2092 6.77087 12.6066 6.77087 13.021C6.77087 13.4354 6.93549 13.8328 7.22852 14.1259C7.52155 14.4189 7.91897 14.5835 8.33337 14.5835H23.4375C23.8519 14.5835 24.2494 14.4189 24.5424 14.1259C24.8354 13.8328 25 13.4354 25 13.021C25 12.6066 24.8354 12.2092 24.5424 11.9161C24.2494 11.6231 23.8519 11.4585 23.4375 11.4585Z" +
      "M23.4375 18.75H8.33337C7.91897 18.75 7.52155 18.9146 7.22852 19.2076C6.93549 19.5007 6.77087 19.8981 6.77087 20.3125C6.77087 20.7269 6.93549 21.1243 7.22852 21.4174C7.52155 21.7104 7.91897 21.875 8.33337 21.875H23.4375C23.8519 21.875 24.2494 21.7104 24.5424 21.4174C24.8354 21.1243 25 20.7269 25 20.3125C25 19.8981 24.8354 19.5007 24.5424 19.2076C24.2494 18.9146 23.8519 18.75 23.4375 18.75Z" +
      "M2.60417 8.33333C4.04241 8.33333 5.20833 7.16741 5.20833 5.72917C5.20833 4.29093 4.04241 3.125 2.60417 3.125C1.16593 3.125 0 4.29093 0 5.72917C0 7.16741 1.16593 8.33333 2.60417 8.33333Z" +
      "M2.60417 15.1043C4.04241 15.1043 5.20833 13.9384 5.20833 12.5002C5.20833 11.0619 4.04241 9.896 2.60417 9.896C1.16593 9.896 0 11.0619 0 12.5002C0 13.9384 1.16593 15.1043 2.60417 15.1043Z" +
      "M2.60417 22.3958C4.04241 22.3958 5.20833 21.2299 5.20833 19.7917C5.20833 18.3534 4.04241 17.1875 2.60417 17.1875C1.16593 17.1875 0 18.3534 0 19.7917C0 21.2299 1.16593 22.3958 2.60417 22.3958Z"
  ]
};
