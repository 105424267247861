export default {
  prefix: "fapzse",
  iconName: "edit-button",
  icon: [
    18,
    18,
    [],
    "asfcbc130",
    "M15.9432 6.4102L17.0996 5.25455C18.2996 4.05449 18.2996 2.10034 17.0996 0.899353C16.5177 0.319215 15.7452 0 14.9218 0C14.0984 0 13.325 0.319215 12.7439 0.900278L11.5893 2.05593L15.9432 6.4102ZM10.5846 3.06076L2.02499 11.6203C1.84274 11.8026 1.70396 12.0274 1.62347 12.2717L0.0368141 17.0664C-0.048301 17.3209 0.018311 17.6021 0.208895 17.7918C0.344894 17.9278 0.525301 18 0.711259 18C0.786197 18 0.861135 17.988 0.935149 17.9639L5.7275 16.3762C5.97267 16.2957 6.19748 16.1569 6.38067 15.9746L14.9384 7.41503L10.5846 3.06076Z"
  ]
};
