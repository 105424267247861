export default {
  prefix: "fapzse",
  iconName: "image",
  icon: [
    20,
    17,
    [],
    "asfcbc96",
    "M19.9841 3.22339C19.9702 1.25686 18.7342 0.0347198 16.7582 0.0208319C12.2796 -0.00694396 7.80193 -0.00694396 3.3252 0.0208319C1.27991 0.0319422 0.0660372 1.26241 0.0494088 3.31505C0.0244662 6.18429 0.0494088 9.05354 0.0494088 11.9256C0.0494088 12.2033 -0.161217 12.5755 0.501146 12.9866C1.71779 11.7811 2.79586 10.4423 3.99033 9.22019C5.1848 7.99805 6.26288 8.05638 7.51278 9.16742C8.35275 9.89214 9.14777 10.6676 9.8934 11.4895C10.5253 12.22 10.9216 12.2005 11.6034 11.5089C12.9004 10.1951 14.0256 10.1951 15.3032 11.5089C16.7388 12.9977 18.0579 14.5976 19.427 16.1503L19.9813 15.6809C19.9951 11.5423 20.0145 7.38143 19.9841 3.22339ZM13.6542 8.88966C13.1593 8.89738 12.6733 8.75735 12.258 8.4874C11.8428 8.21745 11.5171 7.82978 11.3223 7.37373C11.1276 6.91768 11.0726 6.41387 11.1644 5.92642C11.2562 5.43896 11.4907 4.98991 11.8379 4.6364C12.1851 4.28289 12.6295 4.04092 13.1143 3.94127C13.5992 3.84162 14.1027 3.8888 14.5607 4.07681C15.0187 4.26482 15.4106 4.58515 15.6864 4.99705C15.9623 5.40894 16.1096 5.89377 16.1096 6.38983C16.1048 7.04463 15.8457 7.6718 15.3874 8.13844C14.929 8.60508 14.3074 8.87452 13.6542 8.88966Z"
  ]
};
