export default {
  prefix: "fapzse",
  iconName: "send",
  icon: [
    24,
    25,
    [],
    "asfcbc124",
    "M11.081 12.9248C11.081 14.322 11.0547 15.6003 11.1074 16.8786C11.1338 17.3245 11.2919 17.9488 11.5818 18.0974C11.8454 18.2461 12.4253 17.9785 12.6889 17.711C15.0084 15.1544 17.3015 12.5384 19.5947 9.95206C20.1482 9.32778 20.1218 8.79269 19.5947 8.1684C17.2752 5.5821 14.982 2.96607 12.6625 0.409496C12.3989 0.112219 11.8718 -0.0661463 11.5291 0.0230366C11.3183 0.0824919 11.1074 0.7365 11.081 1.12296C11.002 2.4607 11.4501 4.15518 10.8702 5.01728C10.3167 5.87938 8.73518 5.81992 7.62814 6.26584C2.72555 8.31704 -0.200188 13.1032 0.0106754 18.692C0.0370331 19.1379 0.406046 19.5838 0.616911 20C0.933207 19.673 1.48672 19.4352 1.5658 19.0487C2.40926 14.649 6.46839 12.4195 11.081 12.9248Z"
  ]
};
