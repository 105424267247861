export default {
  prefix: "fapzse",
  iconName: "comment-progress",
  icon: [
    24,
    25,
    [],
    "asfcbc124",
    "M19.9988 10.0141C19.9988 12.9784 18.5967 15.8182 16.2934 17.6864C15.1667 18.6081 13.8397 19.2807 12.4126 19.6294C11.2609 19.9284 8.88242 20.5013 8.28154 19.0814C8.63206 18.5583 9.33309 18.3839 9.98405 18.359C14.8913 18.1597 18.9222 14.2986 18.4465 9.19201C18.0209 4.58361 13.7646 1.14599 9.18287 1.59437C8.90746 1.61928 8.58198 1.56946 8.40672 1.32036C8.33161 1.19581 8.30658 1.04635 8.28154 0.896884C8.2565 0.672691 8.30658 0.398678 8.4568 0.224306C8.68213 0.000113199 9.00761 0.000113291 9.30805 0.000113291C14.9914 -0.024797 20.0239 4.06049 19.9988 10.0141Z" +
      "M8.60891 11.8324C10.061 10.3378 11.5382 8.86812 12.9904 7.37351C13.2157 7.14931 13.5161 6.90021 13.8166 6.95003C14.2422 7.04967 14.3424 7.64752 14.1671 8.071C13.9918 8.49447 13.6664 8.8183 13.3409 9.14214C12.1642 10.3129 10.9874 11.5086 9.81068 12.6794C9.53527 12.9534 9.23483 13.2523 8.83424 13.3022C8.38358 13.352 7.95795 13.0281 7.60743 12.7292C7.15677 12.3307 6.73114 11.8823 6.30551 11.4339C5.95499 11.0353 5.57944 10.562 5.62951 10.014C5.62951 9.91436 5.67959 9.78981 5.7547 9.73998C5.82981 9.69016 5.92996 9.69016 6.00507 9.69016C6.73114 9.69016 7.13173 10.1385 7.55736 10.6368C7.68254 10.7115 8.68402 11.7577 8.60891 11.8324Z" +
      "M1.52266 10.0639C1.57273 10.861 1.37244 11.683 0.671403 11.5336C0.44607 11.4837 0.245775 11.2845 0.145627 11.0852C0.0454795 10.861 0.0204425 10.6368 0.0204425 10.3877C-0.00459438 9.91439 -0.0296312 9.41619 0.0955534 8.94289C0.145627 8.69379 0.270812 8.44469 0.521181 8.36995C0.77155 8.29522 1.02192 8.4696 1.17214 8.66888C1.3474 8.86816 1.49762 9.46601 1.52266 10.0639Z" +
      "M6.98029 1.89327C6.3794 2.49112 4.72696 2.9395 4.2763 3.13878C4.12608 2.86477 4.02593 2.56585 3.92578 2.26692C4.47659 1.94309 5.00237 1.61925 5.55318 1.29542C5.92874 1.07123 6.3794 0.847036 6.77999 0.971587C6.95525 1.02141 7.10547 1.17087 7.15554 1.34524C7.23066 1.54452 7.13051 1.7189 6.98029 1.89327Z" +
      "M3.17545 4.459C3.50093 5.05685 2.47442 6.00344 1.94864 6.6262C1.9236 6.67602 1.87353 6.72584 1.82346 6.72584C1.77338 6.75075 1.69827 6.70093 1.6482 6.67602C1.4479 6.55147 1.22257 6.42692 1.02227 6.32728C0.9722 6.30237 0.922126 6.27745 0.897089 6.22763C0.872052 6.17781 0.897089 6.10308 0.922126 6.05326C1.24761 5.4305 1.57309 4.83266 1.94864 4.23481C1.99871 4.16008 2.04879 4.06044 2.14894 4.03553C2.22405 4.01062 2.29916 4.01062 2.37427 4.03553C2.84997 4.11026 3.0753 4.28463 3.17545 4.459Z" +
      "M3.20037 15.2702C3.25044 15.5442 3.15029 15.7684 2.82481 15.8929C2.59948 15.9676 2.14882 15.9926 2.04867 15.8431C1.598 15.2203 1.19741 14.5478 0.846897 13.8503C0.621564 13.4019 1.39771 12.8788 1.7983 13.1279C1.94852 13.2275 2.02363 13.3521 2.12378 13.5015C2.34911 13.8254 3.10022 14.6474 3.20037 15.2702Z" +
      "M6.78082 18.5832C6.75578 18.7576 6.65564 18.907 6.35519 18.9818C5.82942 19.1312 4.35224 17.8857 3.92661 17.6117C3.87654 17.5868 4.0518 16.964 4.25209 16.8146C4.67772 16.5157 5.60409 17.1384 5.92957 17.3875C6.17993 17.5619 6.80586 18.1597 6.78082 18.5832Z"
  ]
};
