export default {
  prefix: "fapzse",
  iconName: "iebc-letter-type",
  icon: [
    75,
    75,
    [],
    "asfcbc117",
    "M28.7289 0.375932C32.848 0.0978448 36.967 -0.736416 40.4682 1.58097C43.7634 3.80567 41.395 10.4798 41.395 15.2072C41.498 29.297 42.6307 30.4093 58.386 30.502C63.6378 30.502 70.6402 28.4627 73.6265 31.3363C76.2009 33.839 74.3473 40.1423 74.4503 44.6844C74.4503 51.6366 74.5532 58.5888 74.4503 65.5409C74.3473 71.5661 70.9491 74.8105 64.3586 74.9032C53.3402 74.9959 42.4247 74.8105 31.4063 74.9959C28.7289 74.9959 28.0081 74.347 28.0081 71.9369C27.6992 60.1646 27.5962 60.1646 40.5712 60.1646C41.292 60.1646 41.9099 60.2573 42.6307 60.1646C45.3081 59.8865 49.4271 61.2769 49.3241 57.291C49.3241 53.6759 45.411 54.7882 42.8366 54.6956C39.5414 54.6029 36.2462 54.4175 33.0539 54.6956C29.2438 55.0663 30.0676 52.7489 29.9646 50.895C29.9646 48.9484 29.2438 46.9091 33.0539 47.0945C39.5414 47.3726 46.0289 47.1872 52.6194 47.1872C54.8848 47.1872 57.5622 47.1872 57.5622 44.499C57.6652 41.7182 55.0908 41.6255 52.7223 41.6255C46.2348 41.6255 39.6444 41.5328 33.1569 41.7182C30.3765 41.8109 29.7587 40.9766 29.7587 38.5665C29.9646 34.3025 29.0379 2.69332 28.7289 0.375932Z" +
      "M24.9188 39.7716C25.6396 50.3389 21.3146 60.9062 22.8593 71.8443C23.0652 73.6055 22.0355 74.9033 19.9759 74.996C17.6075 75.0887 16.7837 73.6055 16.7837 71.6589C16.7837 66.2826 16.5777 60.8135 16.8867 55.4372C17.0926 52.3782 15.0331 52.6563 12.9736 52.8417C11.12 53.0271 8.33966 51.6367 8.44263 55.3445C8.64858 60.7209 8.54561 66.1899 8.54561 71.5662C8.54561 73.5128 7.82478 74.996 5.45633 74.996C3.08788 74.996 2.26407 73.4201 2.36705 71.4735C3.60276 58.3108 -1.23711 45.4261 0.30753 32.1706C0.410506 31.0583 0.204554 29.9459 0.410506 28.9263C0.719434 27.4431 1.13134 25.8673 1.95515 24.5696C3.29383 22.2522 5.45633 19.564 8.44263 20.3983C11.8408 21.4179 11.6349 24.5696 10.6051 27.4431C9.78132 29.6678 9.67834 31.9852 8.85454 34.2099C7.92775 36.62 10.1932 37.4543 11.4289 38.752C12.3557 39.7716 13.2825 39.2155 14.0033 38.4739C15.1361 37.2689 17.0926 36.5273 16.3718 34.3026C15.445 31.5217 15.1361 28.5555 14.1063 25.7746C13.2825 23.3645 14.5182 21.696 16.1658 20.5837C18.0194 19.2859 19.667 21.0471 21.0057 22.0668C23.5801 24.0134 24.9188 26.7016 24.9188 29.8532C24.9188 33.0049 24.9188 36.2492 24.9188 39.7716Z" +
      "M71.1552 24.9401C64.7707 24.9401 58.1803 24.9401 51.5898 24.9401C49.2213 24.9401 47.6767 24.0131 47.5737 21.6958C47.5737 15.5778 47.5737 9.55264 47.5737 3.62012C55.3999 10.7577 63.2261 17.8025 71.1552 24.9401Z" +
      "M20.8005 8.34768C20.8005 12.3336 17.0933 15.6706 12.6654 15.6706C8.03144 15.7633 4.22133 12.1482 4.32431 7.9769C4.53026 3.99099 8.23739 0.746645 12.6654 0.839341C17.1963 0.932036 20.8005 4.26908 20.8005 8.34768Z"
  ]
};
