export default {
  prefix: "fapzse",
  iconName: "user-comment",
  icon: [
    24,
    25,
    [],
    "asfcbc121",
    "M15.8329 9.38537C13.5242 8.97176 11.0572 10.5775 10.2876 12.9619C9.54069 15.3462 10.6045 18.2415 12.6642 19.4094C12.981 19.5797 13.2979 19.7256 13.6374 19.823C15.9687 20.5042 18.6395 19.1661 19.6354 16.7331C20.8576 13.6675 18.8658 9.92063 15.8329 9.38537ZM14.9502 11.2101C15.7423 11.3318 16.3082 11.794 16.2856 12.6699C16.2856 13.5458 15.7423 14.1054 14.9275 14.0811C14.158 14.0811 13.66 13.5215 13.6374 12.7186C13.6148 11.8184 14.1353 11.3561 14.9502 11.2101ZM17.5531 16.5384C17.0551 17.7792 15.9913 17.9009 14.9049 18.0469C13.8864 17.8766 12.8452 17.7549 12.3699 16.5871C12.0078 15.6869 12.302 15.1516 13.1847 15.1273C14.3617 15.1029 15.516 15.1029 16.693 15.1273C17.5983 15.1273 17.9152 15.6382 17.5531 16.5384Z" +
      "M18.1184 3.1325C18.0731 1.3564 16.8735 0.0425776 15.2439 0.0182476C11.1245 -0.00608252 7.02779 -0.00608252 2.90844 0.0182476C1.27881 0.0425776 0.0792183 1.3564 0.0339507 3.1325C-0.0113169 5.93045 -0.0113169 8.72841 0.0339507 11.5264C0.0565845 12.9375 0.826133 14.2757 2.07099 14.3487C3.90433 14.4703 3.72326 15.6138 3.65536 16.879C3.65536 17.0006 3.65536 17.1223 3.65536 17.2439C3.76853 18.2901 4.62861 18.8011 5.44343 18.2171C6.59775 17.3899 7.68417 16.441 8.81586 15.5652C9.01957 15.4192 9.1101 15.2245 9.08747 14.9569C8.99693 12.9618 9.72121 11.2831 11.1471 9.75027C9.178 9.75027 7.41257 9.75027 5.66976 9.75027C5.14919 9.75027 4.60598 9.75027 4.58334 9.02037C4.58334 8.29047 5.14919 8.29047 5.64713 8.29047C6.64302 8.29047 7.6389 8.26614 8.63479 8.29047C9.06483 8.29047 9.51751 8.3148 9.94755 8.3148C10.3097 8.3148 10.6718 8.19315 11.034 8.26614C11.3056 8.3148 11.5546 8.48511 11.6677 8.75274C11.713 8.85006 11.7356 8.97171 11.7809 9.06903C12.0525 9.48264 12.9126 8.70408 13.2068 8.60676C13.75 8.43645 14.2932 8.33913 14.8591 8.33913C15.4249 8.33913 15.9681 8.43645 16.5114 8.58243C16.6924 8.63109 18.0957 9.06903 18.0957 9.26367C18.1184 7.0983 18.1636 5.10323 18.1184 3.1325ZM12.5957 6.34406C11.4187 6.34406 10.2644 6.34406 9.08747 6.34406C7.91051 6.34406 6.75619 6.34406 5.57923 6.34406C5.10392 6.34406 4.60598 6.2954 4.56071 5.66282C4.51544 4.93292 5.05865 4.88426 5.57923 4.88426C7.91051 4.88426 10.2418 4.88426 12.5957 4.88426C13.0936 4.88426 13.569 4.98158 13.5463 5.61416C13.569 6.24674 13.0936 6.34406 12.5957 6.34406Z"
  ]
};
