import cookies from "js-cookie";
import _ from "lodash";
// import moment from "moment";
import store from "@/store";
import {
  httpSuccessResponseLogger,
  httpErrorResponseLogger
} from "@/helpers/logger";
import { redirectToProperErrorPage } from "@/helpers/router";

export default function({ next, router, to }) {
  const auth_token = cookies.get("auth_token")
    ? JSON.parse(cookies.get("auth_token"))
    : {};
  const user = cookies.get("user") ? JSON.parse(cookies.get("user")) : {};
  const permissions = cookies.get("permissions")
    ? JSON.parse(cookies.get("permissions"))
    : {};

  if (!_.isEmpty(auth_token)) {
    store.commit("auth/SET_AUTH_TOKEN", auth_token);

    if (_.isEmpty(user) || _.isEmpty(permissions)) {
      store
        .dispatch("auth/currentUser")
        .then(response => {
          httpSuccessResponseLogger(response);
          return next();
        })
        .catch(error => {
          httpErrorResponseLogger(error);

          store.commit("auth/INVALIDATE_AUTH_TOKEN");

          redirectToProperErrorPage(error, to, router);
        });
    } else {
      store.commit("auth/SET_USER", user);

      store.commit("auth/SET_PERMISSIONS", permissions);
    }
  }

  return next();
}
