export default {
  prefix: "fapzse",
  iconName: "recycle-bin",
  icon: [
    18,
    18,
    [],
    "asfcbc175",
    "M8.55967 0C6.98115 0 5.66631 1.17949 5.44482 2.7H0.684668C0.44209 2.69648 0.215332 2.82481 0.0922853 3.03398C-0.0307618 3.24492 -0.0307618 3.50508 0.0922853 3.71602C0.215332 3.92519 0.44209 4.05352 0.684668 4.05H1.64619L2.77998 15.7641C2.90303 17.0385 3.96299 18 5.24443 18H11.8749C13.1563 18 14.2163 17.0385 14.3394 15.7641L15.4731 4.05H16.4347C16.6772 4.05352 16.904 3.92519 17.0271 3.71602C17.1501 3.50508 17.1501 3.24492 17.0271 3.03398C16.904 2.82481 16.6772 2.69648 16.4347 2.7H11.6745C11.453 1.17949 10.1382 0 8.55967 0ZM8.55967 1.35C9.40518 1.35 10.0995 1.92129 10.2981 2.7H6.82295C7.01982 1.92129 7.71416 1.35 8.55967 1.35ZM6.53467 6.3C6.90732 6.3 7.20967 6.60234 7.20967 6.975V13.725C7.20967 14.0977 6.90732 14.4 6.53467 14.4C6.16201 14.4 5.85967 14.0977 5.85967 13.725V6.975C5.85967 6.60234 6.16201 6.3 6.53467 6.3ZM10.5847 6.3C10.9573 6.3 11.2597 6.60234 11.2597 6.975V13.725C11.2597 14.0977 10.9573 14.4 10.5847 14.4C10.212 14.4 9.90967 14.0977 9.90967 13.725V6.975C9.90967 6.60234 10.212 6.3 10.5847 6.3Z"
  ]
};
