export default {
  prefix: "fapzse",
  iconName: "anchorage-type",
  icon: [
    23,
    20,
    [],
    "asc116",
    "M17.5116 0.71964C17.2539 0.27538 16.7798 0 16.2663 0H6.38061C5.86648 0 5.39238 0.27266 5.13531 0.71964L0.1928 9.2821C-0.0642665 9.727 -0.0642665 10.2757 0.1928 10.72L5.13531 19.281C5.39238 19.726 5.86648 20 6.38061 20H16.267C16.7804 20 17.2552 19.7267 17.5116 19.281L22.4548 10.72C22.7119 10.2757 22.7119 9.727 22.4548 9.2821L17.5116 0.71964ZM6 10.3481C6 7.39834 8.39835 5 11.3467 5C14.2958 5 16.6948 7.39834 16.6948 10.3481C16.6948 13.2985 14.2958 15.6948 11.3467 15.6948C8.39835 15.6948 6 13.2985 6 10.3481Z" +
      "M11.3467 12.8197C9.98344 12.8197 8.87449 11.71 8.87449 10.3467C8.87449 8.98214 9.98344 7.87381 11.3467 7.87381C12.71 7.87381 13.819 8.98344 13.819 10.3467C13.819 11.71 12.71 12.8197 11.3467 12.8197Z"
  ]
};
