export default {
  prefix: "fapzse",
  iconName: "next",
  icon: [
    30,
    30,
    [],
    "asfcbc87",
    "M30 15.1264L23.8313 21.2591C23.3406 21.7472 22.8344 22.2259 22.0625 21.8849C21.2906 21.5438 21.25 20.9086 21.25 20.1921C21.225 19.1658 21.7156 17.7766 21.0469 17.2197C20.3781 16.6627 19.0625 17.0225 18.0406 17.0038C16.1094 16.9662 14.9656 16.0056 15.0094 14.4287C15.05 12.9143 16.1531 12.0194 18.0156 11.9913C21.1656 11.9475 21.175 11.9475 21.25 8.82794C21.25 8.11768 21.3125 7.45748 22.05 7.11956C22.7875 6.78164 23.3438 7.21656 23.8188 7.72031C25.8625 9.88238 28.2406 11.7378 30 14.1878V15.1264Z" +
      "M0 13.5619C0.2 13.3304 0.3875 13.0895 0.6 12.8704C1.65 11.7816 3.19063 11.7065 4.22188 12.6827C4.46582 12.918 4.65987 13.2001 4.79241 13.5122C4.92495 13.8243 4.99326 14.1599 4.99326 14.499C4.99326 14.8382 4.92495 15.1738 4.79241 15.4859C4.65987 15.798 4.46582 16.0801 4.22188 16.3154C3.1875 17.2947 1.65 17.2196 0.6 16.1308C0.390625 15.9086 0.2 15.6677 0 15.4393C0 14.8135 0 14.1877 0 13.5619Z" +
      "M12.5 14.4788C12.5043 14.9757 12.3608 15.4626 12.0878 15.8776C11.8148 16.2926 11.4246 16.6168 10.9669 16.809C10.5092 17.0011 10.0047 17.0525 9.51771 16.9567C9.03073 16.8608 8.58327 16.6219 8.23233 16.2706C7.88138 15.9192 7.64284 15.4712 7.54707 14.9836C7.45131 14.496 7.50266 13.9909 7.69458 13.5326C7.88651 13.0743 8.21032 12.6836 8.62478 12.4103C9.03924 12.1369 9.52558 11.9932 10.0219 11.9975C10.3475 11.9967 10.6702 12.0603 10.9712 12.1847C11.2722 12.3091 11.5457 12.4919 11.776 12.7224C12.0063 12.953 12.1888 13.2269 12.313 13.5283C12.4373 13.8297 12.5008 14.1527 12.5 14.4788Z"
  ]
};
