export default {
  prefix: "fapzse",
  iconName: "ahj-exchange",
  icon: [
    49,
    49,
    [],
    "asfcbc177",
    "M24.375 49C37.8076 49 48.75 38.0576 48.75 24.625C48.75 11.1924 37.8076 0.249999 24.375 0.249998C10.9424 0.249997 3.30525e-06 11.1924 2.13093e-06 24.625C9.56613e-07 38.0576 10.9424 49 24.375 49ZM24.375 45.25C12.9639 45.25 3.75 36.0361 3.75 24.625C3.75 13.2139 12.9639 4 24.375 4C35.7861 4 45 13.2139 45 24.625C45 36.0361 35.7861 45.25 24.375 45.25ZM25.2539 37.6328L37.7637 24.625L25.2539 11.6172L22.5586 14.1953L30.7324 22.7353L11.25 22.75L11.25 26.5L30.7764 26.4853L22.5586 35.0547L25.2539 37.6328Z"
  ]
};
