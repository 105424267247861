export default {
  prefix: "fapzse",
  iconName: "electrical-fault-study-type",
  icon: [
    75,
    75,
    [],
    "asfcbc117",
    "M37.7371 64.497C39.3286 64.497 40.8264 64.497 42.4179 64.497C47.1924 64.497 48.0349 65.5424 46.2562 69.8112C45.0392 72.7733 42.6988 74.5156 39.3286 74.8641C35.7712 75.2997 32.4946 74.777 30.0605 71.9892C28.3754 70.0726 27.5329 67.7204 28.2818 65.5424C29.1244 63.2773 31.7456 64.8455 33.5244 64.5841C34.9286 64.3228 36.3329 64.497 37.7371 64.497Z" +
      "M37.5504 59.2699C35.8653 59.2699 34.0866 59.2699 32.4015 59.2699C27.7207 59.2699 27.0654 58.3987 28.5632 54.217C30.0611 50.2967 33.993 48.2929 39.0483 48.9028C43.4483 49.4255 45.9759 51.8648 46.7248 55.8723C47.1929 58.2245 46.7248 59.7926 43.5419 59.357C41.5759 59.0085 39.5164 59.2699 37.5504 59.2699Z" +
      "M10.121 31.3921C11.9933 31.5663 13.0231 32.3504 13.1167 33.8314C13.2103 35.4867 12.1805 36.6192 10.4018 36.6192C8.62311 36.6192 7.49971 35.5738 7.59332 33.8314C7.68694 32.2633 8.90395 31.6534 10.121 31.3921Z" +
      "M37.2693 31.3921C38.8608 31.3921 39.9842 32.1762 40.2651 33.5701C40.5459 35.2253 39.5161 36.3578 37.7374 36.5321C35.9587 36.7063 34.8353 35.6609 34.7417 33.9185C34.7417 32.4375 35.7715 31.6534 37.2693 31.3921Z" +
      "M67.5077 34.18C67.1332 35.7481 66.197 36.7935 64.3247 36.6193C62.8268 36.4451 61.797 35.4867 61.8907 33.9186C62.0779 32.2634 63.2013 31.3051 64.98 31.4793C66.5715 31.5664 67.414 32.6118 67.5077 34.18Z" +
      "M13.2098 42.63C13.0225 44.1981 12.0864 45.3306 10.214 45.2435C8.62255 45.1564 7.59277 44.2852 7.59277 42.8042C7.59277 41.1489 8.62255 40.1035 10.4949 40.1035C12.0864 40.1906 12.9289 41.1489 13.2098 42.63Z" +
      "M22.5721 42.456C22.2912 44.2855 21.4487 45.3309 19.5763 45.3309C17.9849 45.2438 16.9551 44.3726 16.9551 42.8916C16.9551 41.2363 17.9849 40.1909 19.8572 40.1909C21.4487 40.1909 22.1976 41.2363 22.5721 42.456Z" +
      "M28.9377 40.1035C30.5291 40.1906 31.6525 40.9747 31.8398 42.4557C32.027 44.111 30.9972 45.2435 29.2185 45.3306C27.4398 45.4177 26.3164 44.3723 26.3164 42.63C26.3164 41.0618 27.4398 40.2778 28.9377 40.1035Z" +
      "M37.2699 45.3308C35.5848 44.9823 34.4614 44.024 34.7422 42.2816C35.0231 40.8877 36.0529 40.0165 37.6443 40.1036C39.2358 40.2779 40.172 41.1491 40.172 42.6301C40.2656 44.3724 39.0486 45.2436 37.2699 45.3308Z" +
      "M45.8822 45.3306C44.1034 45.0693 42.98 44.1981 43.1673 42.4557C43.2609 40.9747 44.3843 40.1035 45.9758 40.1035C47.5673 40.1035 48.597 41.0618 48.6907 42.4557C48.7843 44.1981 47.6609 45.0693 45.8822 45.3306Z" +
      "M55.2438 40.1035C56.8352 40.2778 57.9586 41.0618 58.0522 42.4557C58.1459 44.111 57.1161 45.2435 55.3374 45.2435C53.5587 45.2435 52.4353 44.1981 52.5289 42.4557C52.6225 41.0618 53.7459 40.2778 55.2438 40.1035Z" +
      "M67.5076 42.63C67.3204 44.1981 66.3842 45.3306 64.5119 45.2435C62.9204 45.1564 61.8906 44.2852 61.8906 42.8042C61.8906 41.1489 62.9204 40.1035 64.7927 40.1035C66.3842 40.1906 67.2268 41.1489 67.5076 42.63Z" +
      "M18.4525 2.55569C14.427 -0.667701 8.90358 -0.841939 4.69084 2.03297C0.478094 4.90789 -1.30062 10.135 1.03979 14.3167C2.91212 17.5401 4.22275 20.7635 5.15892 24.2482C5.90785 27.036 8.34188 27.9943 11.244 27.733C14.8014 27.9943 16.9546 26.3391 17.6099 23.2899C18.2652 20.2408 19.4822 17.6272 21.1673 14.9265C23.8822 10.8319 22.478 5.77907 18.4525 2.55569ZM11.4312 19.1082C10.1206 19.1953 5.81423 12.7486 5.72062 10.135C6.00147 7.34721 7.68656 5.34348 11.1504 5.25636C14.6142 5.16924 16.5801 7.08585 16.861 10.3092C17.0482 12.2258 12.6482 19.1082 11.4312 19.1082Z" +
      "M37.2689 0.0294794C29.0306 0.203717 23.6009 8.5671 27.72 15.2752C29.3115 17.8017 30.4349 20.3281 31.0902 23.203C31.7455 26.3393 33.7115 27.8203 37.2689 27.9074C40.9199 27.9945 43.2603 26.6006 44.0093 23.2901C44.571 20.4152 45.788 17.8888 47.3795 15.3623C51.4986 8.39286 45.788 -0.144758 37.2689 0.0294794ZM38.954 18.76C38.8604 19.3698 36.2391 19.4569 36.1455 18.8471C35.3965 15.7108 31.9327 13.7071 32.0264 10.3966C32.12 7.08608 34.3668 5.51795 37.737 5.25659C41.1072 5.7793 43.1667 7.43456 43.0731 10.7451C42.9795 13.7942 39.6093 15.7108 38.954 18.76Z" +
      "M70.6903 2.38165C66.4776 -0.841738 60.767 -0.754619 56.5543 2.55589C52.3415 5.86639 51.1245 11.0935 53.933 15.4494C55.6181 17.9759 56.6479 20.5894 57.2096 23.3772C57.8649 26.5135 59.9245 28.1687 63.6691 27.9074C67.0393 28.2559 69.1925 26.7748 69.9414 23.6386C70.6903 20.6765 71.7201 17.9759 73.4052 15.2752C76.401 10.9193 74.9967 5.60504 70.6903 2.38165ZM69.1925 11.3549C68.5372 14.0555 65.9159 15.885 65.2606 18.6728C65.0734 19.3698 62.4521 19.8925 61.8904 18.2372C61.1415 15.885 59.3628 14.2298 58.4266 12.1389C56.9287 8.82842 59.8308 5.16944 63.6691 5.16944C67.2265 5.25656 70.035 8.30571 69.1925 11.3549Z"
  ]
};
