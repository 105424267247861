import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";

Vue.use(Vuetify);

const theme = {
  primary: "#1e88e5",
  info: "#1e88e5",
  success: "#21c1d6",
  accent: "#fc4b6c",
  default: "#563dea",
  mercury: "#e5e5e5",
  "concrete-solid": "#f2f2f2",
  concrete: "#f3f3f3",
  "web-orange": "#f5a800",
  biscay: "#1d285c",
  tuna: "#34313c",
  "dove-gray": "#707070",
  "gray-approx": "#8d8989",
  "dusty-gray": "#9c9c9c",
  gallery: "#ebebeb",
  nobel: "#b6b6b6",
  "outer-space": "#343a40",
  fiejoa: "#8ed891",
  silver: "#c7c7c7",
  cascade: "#95a5a6",
  "sunset-orange": "#ff5050",
  "dodger-blue": "#1b9cfc",
  "fountain-blue": "#66bbc1",
  "kashimir-blue": "#506498",
  alabaster: "#f7f7f7",
  fern: "#66bb6a",
  "coral-red": "#f44",
  "lucky-point": "#171d64",
  "mine-shaft": "#2d2d2d",
  "gull-gray": "#90a4ae",
  chalky: "#edd49c",
  "red-orange": "#fd2626",
  "rose-white": "#fff8f8",
  sun: "#fbd415",
  "island-spice": "#fff9ed",
  panache: "#f4f4f4",
  zircon: "#f8f9ff",
  "sugar-cane": "#f8fff8",
  "twilight-blue": "#f0feff",
  "alice-blue": "#f1f9ff",
  "gray-chateau": "#a0a4a8",
  "school-bus-yellow": "#fdda00",
  alto: "#dddddd",
  "wild-sand": "#f6f6f6",
  bunting: "#191e5a",
  gold: "#fdd600",
  "white-lilac": "#f7f7fc",
  "santas-gray": "#9a9ab0",
  ebony: "#11142d",
  "carnation-pink": "#ffadc8",
  comet: "#595d87"
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme
    },
    dark: false
  },
  rtl: false
});
