import store from "@/store";
import {
  httpSuccessResponseLogger,
  httpErrorResponseLogger
} from "@/helpers/logger";
import { redirectToProperErrorPage } from "@/helpers/router";

export default function({ next, to, router }) {
  store
    .dispatch("providers/projectTypes/getData")
    .then(response => {
      httpSuccessResponseLogger(response);

      return next();
    })
    .catch(error => {
      httpErrorResponseLogger(error);

      redirectToProperErrorPage(error, to, router);
    });
}
