export default {
  prefix: "fapzse",
  iconName: "transactions",
  icon: [
    25,
    25,
    [],
    "asfcbc102",
    "M17.2729 14.625H14.5833V12.375H19.3958C20.2313 11.646 21.2656 11.25 22.3573 11.25C23.3135 11.25 24.226 11.5538 25 12.1196V5.625C25 3.76425 23.5979 2.25 21.875 2.25H3.125C1.40208 2.25 0 3.76425 0 5.625V24.75H10.4167V22.0286L17.2729 14.625ZM14.5833 7.875H20.8333V10.125H14.5833V7.875ZM8.70417 14.895L5.53646 14.3246C4.13854 14.0737 3.125 12.78 3.125 11.25C3.125 9.38925 4.52708 7.875 6.25 7.875V6.75H8.33333V7.875C10.0563 7.875 11.4583 9.38925 11.4583 11.25H9.375C9.375 10.6301 8.90833 10.125 8.33333 10.125H6.25C5.675 10.125 5.20833 10.6301 5.20833 11.25C5.20833 11.6753 5.49063 12.0353 5.87917 12.105L9.04688 12.6754C10.4448 12.9263 11.4583 14.22 11.4583 15.75C11.4583 17.6108 10.0563 19.125 8.33333 19.125V20.25H6.25V19.125C4.52708 19.125 3.125 17.6108 3.125 15.75H5.20833C5.20833 16.3699 5.675 16.875 6.25 16.875H8.33333C8.90833 16.875 9.375 16.3699 9.375 15.75C9.375 15.3247 9.09271 14.9647 8.70417 14.895ZM25.001 16.3553C25.001 17.118 24.726 17.8358 24.226 18.3746L16.2396 27H12.5V22.9613L20.4875 14.3359C21.5188 13.2221 23.1958 13.2221 24.2271 14.3359C24.726 14.8748 25.0021 15.5925 25.0021 16.3553H25.001Z"
  ]
};
