export default {
  prefix: "fapzse",
  iconName: "bell",
  icon: [
    22,
    25,
    [],
    "asfcbc66",
    "M10.9375 25C12.6621 25 14.0611 23.6011 14.0611 21.875H7.81399C7.81399 23.6011 9.21291 25 10.9375 25ZM21.4546 17.6899C20.5112 16.6763 18.7461 15.1514 18.7461 10.1562C18.7461 6.3623 16.086 3.3252 12.499 2.58008V1.5625C12.499 0.699707 11.7998 0 10.9375 0C10.0752 0 9.376 0.699707 9.376 1.5625V2.58008C5.78909 3.3252 3.12894 6.3623 3.12894 10.1562C3.12894 15.1514 1.3638 16.6763 0.420443 17.6899C0.127475 18.0049 -0.00240764 18.3813 3.37591e-05 18.75C0.00540485 19.5508 0.633822 20.3125 1.56741 20.3125H20.3076C21.2412 20.3125 21.8701 19.5508 21.875 18.75C21.8774 18.3813 21.7476 18.0044 21.4546 17.6899Z"
  ]
};
