import _get from "lodash/get";
import {
  httpSuccessResponseLogger,
  httpErrorResponseLogger
} from "@/helpers/logger";
import api from "@/api";

export default function({ next, to }) {
  const token = _get(to, "query.token");

  api
    .post("/auth/reset-password/token-validity-check", { token })
    .then(response => {
      httpSuccessResponseLogger(response);

      return next();
    })
    .catch(error => {
      httpErrorResponseLogger(error);

      return next({
        path: "forgot-password?token=invalid",
        force: true
      });
    });
}
