export default {
  prefix: "fapzse",
  iconName: "filter-export",
  icon: [
    25,
    25,
    [],
    "asfcbc113",
    "M 8.332031 2.082031 C 7.183594 2.082031 6.25 3.015625 6.25 4.167969 L 6.25 20.832031 C 6.25 21.984375 7.183594 22.917969 8.332031 22.917969 L 16.667969 22.917969 C 17.816406 22.917969 18.75 21.984375 18.75 20.832031 L 18.75 13.542969 L 12.5 13.542969 C 11.925781 13.542969 11.457031 13.074219 11.457031 12.5 C 11.457031 11.925781 11.925781 11.457031 12.5 11.457031 L 18.75 11.457031 L 18.75 4.167969 C 18.75 3.015625 17.816406 2.082031 16.667969 2.082031 Z M 18.75 11.457031 L 18.75 13.542969 L 20.832031 13.542969 L 20.832031 15 C 20.832031 15.335938 21.035156 15.640625 21.347656 15.769531 C 21.453125 15.8125 21.558594 15.832031 21.667969 15.832031 C 21.882812 15.832031 22.09375 15.75 22.253906 15.589844 L 24.753906 13.089844 C 25.082031 12.761719 25.082031 12.234375 24.753906 11.910156 L 22.253906 9.410156 C 22.015625 9.171875 21.660156 9.101562 21.347656 9.230469 C 21.035156 9.359375 20.832031 9.664062 20.832031 10 L 20.832031 11.457031 Z M 18.75 11.457031"
  ]
};
