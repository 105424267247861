export default {
  prefix: "fapzse",
  iconName: "document",
  icon: [
    20,
    20,
    [],
    "asfcbc44",
    "M4.3771 0.0257701C3.59546 1.0922 3.49557 2.10347 3.49557 3.17515C3.51721 7.72805 3.51721 12.281 3.49557 16.8339C3.49557 17.9029 3.59546 18.9168 4.3821 19.9859C3.56299 19.9859 2.92619 20.0148 2.29438 19.9859C0.923383 19.9123 0.0318591 19.014 0.02187 17.5641C-0.00643235 12.5244 -0.00726477 7.48815 0.0193727 2.45544C0.0268645 1.00027 0.915891 0.101944 2.28689 0.0310234C2.90871 0.00213001 3.53303 0.0257701 4.3771 0.0257701Z" +
      "M19.2633 6.32682H14.958C14.3687 6.32682 14.0016 6.06415 13.9991 5.3996C13.9991 3.85249 13.9991 2.30276 13.9991 0.755646L19.2633 6.32682Z" +
      "M19.9999 8.66984C19.9999 8.01318 19.7952 7.86871 19.2183 7.88184C17.8048 7.92124 16.3889 7.91336 14.9729 7.88184C13.3672 7.85295 12.5231 6.96251 12.4957 5.27093C12.4732 3.82626 12.4532 2.38159 12.4957 0.939546C12.5356 0.230344 12.3533 -0.0244429 11.6291 0.00182381C10.1307 0.0622373 8.63238 0.00182381 7.13402 0.0228372C5.31352 0.0412239 4.51189 0.905399 4.49441 2.86752C4.49441 5.23153 4.49441 7.59554 4.49441 9.95954C4.49441 12.4523 4.47444 14.9502 4.49441 17.4403C4.51189 18.9874 5.36097 19.9567 6.78441 19.9724C10.4038 20.0092 14.0231 20.0092 17.6425 19.9724C19.0934 19.9645 19.96 19.0084 19.9775 17.4692C20.0049 14.5378 19.9775 11.6065 19.9999 8.66984ZM13.6219 15.7619C12.8727 15.7619 12.1236 15.7619 11.3744 15.7619C10.7101 15.7619 10.0458 15.7619 9.37657 15.7619C8.90209 15.7619 8.54748 15.5386 8.51751 15.0211C8.48754 14.5037 8.81468 14.2069 9.29416 14.2016C10.7476 14.1832 12.201 14.1859 13.6544 14.2016C14.1314 14.2016 14.466 14.4643 14.451 14.9896C14.436 15.515 14.0989 15.7619 13.6219 15.7776V15.7619ZM14.9854 12.0845C14.0714 12.0845 13.1574 12.0845 12.2384 12.0845C11.3194 12.0845 10.4104 12.0845 9.49144 12.0845C8.99199 12.0845 8.54248 11.9243 8.52001 11.328C8.49753 10.7318 8.90458 10.5085 9.42901 10.5085H15.0454C15.5698 10.5085 15.9793 10.7265 15.9544 11.3254C15.9294 11.9243 15.4974 12.1108 14.9829 12.1055L14.9854 12.0845Z"
  ]
};
