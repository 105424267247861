export default {
  prefix: "fapzse",
  iconName: "archive",
  icon: [
    23,
    21,
    [],
    "asfcbd82",
    "m12.4 0.9v3.9h2.8l-3.8 3.8-3.8-3.8h2.8v-3.9zm-4.8 6.5l-2.8 1.3 5.7 2.7v8.7l-7.7-3.9v-7.3l-2.8-3.1 4.1-1.9zm15.4-1.6l-2.9 3.1v7.3l-7.6 3.9v-8.7l5.7-2.7-2.8-1.3 3.5-3.5z"
  ]
};
