export default {
  prefix: "fapzse",
  iconName: "management",
  icon: [
    20,
    20,
    [],
    "asfcbc54",
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADEElEQVRoge2YvU9UQRTFdyXSsKyRRKTSRA1B8SMhaki0QSs/CrVRsJHGGGMvov8CipgIYi0tiRob0ShoIaINkWAhikQroGAxQiT8LN59cvf5Pmbe7ooh7ySTTO6cueee2Xnzdl4qlSBBggRrCkAGuA68A+aljQDtQGa16wsFsBOYJBhfgLp/WVAaOA8MqdUcBFqAtIebBSZCinfxCaiMq2Nr4H5IIb0ebrsamwNagc3SWiXmoi2ujk3xLQar2az471X8ok++S2p8JK6OjYHXKsEDoFpan4oPKf4PFd/kk69ajc/H1bExoAuqUfGagELiGrDS8WKdtbNgfFT9Mz7jOjZeRF1/AK/UCvSx8kDqn3ZQ8a+p+BxwAaiU5n2Ir8bVsTHQTDTOKn4W54iMQt4xaqtja6I3JOlfxxtQB3wOmTOBz4vMVsfGQFpW6CWQw9kGL4BzBLxggAqc7fRW5uSk3wZUFEsnwX8N4CQwDCwG7MtvQFa4xyO4fliUOcckRxb4HsE9YVN8FC6r4pctCvdiWZm4YsCPNoHzwEWhVrjDBRTv4o3kqjXlRhnQW6HKgrvRM9avxvo9Y1VqbCFCw5jrTviDQrhAgxpuKJVOMf8LeTGj+tOlEimlgT0B/aKilAZ2B/SLisRACFbNQM7tUNh3nC2qvzVuEvK/YOS8434GplR/f1zhVCrVHdC3xQHVn4xkA7fUsfswghv2HigDnkgrs5nr4T1W1A4TA/XAkprUHsLV10Tj7YZzzXQxF8K7oXhLwC5TgTvk4xHQhPPdcwNQLrxxxTloYaBRzRuTWLnkzgBHPCsP0Gma3002QDBOCU9fBY0FgC41r0dip0P0ngLrjQ0oE13kbycXd4VzVMV+AnsN8u4DFtS8Jol3++j8Ajqti/cI1gM3gVGc++os8Ay5qwLPleDXMBNS/JTiD0g8LTlnRWMU6MB0zxcCYAcwo4paAG7j7POMtEb5NfXKTwPbSl6gCYDDUpAppoFDq113HoDtsg2iMPDfrLwfcI7Ae8AYK9+FPgA9yAObIEGCBGsHvwGyzO0m+HRcLAAAAABJRU5ErkJggg=="
  ]
};
