import _get from "lodash/get";
import store from "@/store";
import {
  httpSuccessResponseLogger,
  httpErrorResponseLogger
} from "@/helpers/logger";

export default function({ next, to, router }) {
  store
    .dispatch("providers/projectStatuses/getData")
    .then(response => {
      httpSuccessResponseLogger(response);

      return next();
    })
    .catch(error => {
      httpErrorResponseLogger(error);

      const status = _get(error, "response.status");

      switch (status) {
        case 500:
          router.push({ name: "Error500", params: { "0": to.path } });
          break;
        case 401:
          router.push({ name: "Login" });
          break;
        default:
          router.push({ name: "Error404", params: { "0": to.path } });
      }
    });
}
