export default {
  prefix: "fapzse",
  iconName: "full-structural-type",
  icon: [
    75,
    75,
    [],
    "asfcbc115",
    "M37.8758 74.9646C28.1612 74.9646 18.4333 74.9646 8.69189 74.9646C2.201 74.9646 1.58713 74.2893 1.57707 67.4222C1.57707 57.0332 1.68776 46.6442 1.49656 36.2552C1.4865 33.554 0.339269 30.8529 0.00717631 28.0479C-0.0558798 26.7543 0.298006 25.4756 1.01352 24.4117C5.60243 17.5965 10.0706 10.6671 15.1023 4.25706C17.0736 2.00406 19.8 0.607869 22.7303 0.350802C32.7937 -0.0959252 42.8571 -0.137481 52.8602 0.350802C55.929 0.690194 58.7626 2.2066 60.8002 4.5999C65.7151 10.938 70.3199 17.5259 74.5971 24.339C75.3317 25.4714 74.8588 27.5388 74.7481 29.1595C74.6374 30.7802 74.104 32.11 74.104 33.5852C74.0436 45.1689 73.943 56.7631 74.104 68.3364C74.1845 73.3127 72.192 75.0996 67.5024 74.9957C57.6503 74.7984 47.758 74.9646 37.8758 74.9646ZM65.6608 66.404V29.253C58.2843 29.253 51.2601 29.0868 44.2459 29.3153C42.2361 29.4782 40.2224 29.0564 38.433 28.0979C36.6436 27.1394 35.1502 25.6824 34.1221 23.8923C30.9119 18.9367 27.4199 14.1786 23.7568 8.91133C19.2081 15.4876 15.1023 21.347 11.0769 27.2687C10.3475 28.1986 9.88707 29.3221 9.74855 30.5101C9.65798 42.4055 9.68817 54.2905 9.68817 66.3417H17.7389C17.7389 59.8382 17.7993 53.636 17.7389 47.4441C17.7389 43.5794 19.2987 41.6055 23.1329 41.7198C24.638 41.8009 26.1462 41.8009 27.6513 41.7198C32.2 41.3666 34.0416 43.5171 33.9007 48.161C33.7196 54.1658 33.9007 60.1706 33.9007 66.4144L65.6608 66.404Z" +
      "M50.6447 41.7196C57.5583 41.7196 57.9809 42.2495 57.9608 50.5295C57.9608 58.02 57.5381 58.3732 49.1855 58.342C42.1411 58.342 41.8091 57.8641 41.8594 48.9919C41.8996 42.1248 42.433 41.6885 50.6447 41.7196Z"
  ]
};
