export default {
  prefix: "fapzse",
  iconName: "redline",
  icon: [
    30,
    30,
    [],
    "asfcbc78",
    "M27.5006 22.1813C24.4078 16.0892 21.2857 10.0139 18.1343 3.95547C16.7726 1.34649 14.2244 1.34986 12.8689 3.95547C9.71759 10.0229 6.59549 16.0993 3.5026 22.1847C2.21598 24.7229 3.5026 26.9644 6.18853 26.988C9.26576 27.015 12.343 26.988 15.4202 26.988C18.5507 26.988 21.6811 27.0082 24.8116 26.988C27.4975 26.9611 28.781 24.706 27.5006 22.1813ZM15.4609 9.50715C16.1747 9.48693 16.4376 10.0229 16.4408 10.7307C16.4408 11.7397 16.4408 12.751 16.4408 13.7644C16.4408 14.8296 16.4408 15.8948 16.4408 16.9599C16.4408 17.6678 16.1809 18.2071 15.4672 18.1903C14.8411 18.1903 14.5719 17.6914 14.5719 17.0476C14.5719 14.9173 14.5719 12.7824 14.5719 10.6431C14.5813 10.0094 14.8474 9.524 15.4609 9.50715ZM15.5955 22.9532C14.8567 22.9094 14.3434 22.5217 14.2776 21.7599C14.2463 21.5781 14.2526 21.3912 14.2961 21.2123C14.3396 21.0335 14.4192 20.8671 14.5292 20.7252C14.6392 20.5832 14.7769 20.4691 14.9326 20.391C15.0882 20.313 15.2579 20.2728 15.4296 20.2734C15.587 20.2498 15.7471 20.2618 15.8999 20.3087C16.0527 20.3557 16.1946 20.4365 16.3167 20.546C16.4387 20.6555 16.5381 20.7913 16.6086 20.9446C16.679 21.098 16.7189 21.2654 16.7256 21.4363C16.8258 22.3026 16.3249 22.7644 15.5955 22.9532Z"
  ]
};
