export default {
  prefix: "fapzse",
  iconName: "arrow-left",
  icon: [
    200,
    110,
    [],
    "azbc79",
    "M56.57 110L70.71 94.6973L38.29 61.7529H200V42.4561H38.29L70.71 12.8223L56.57 0L0 52.5098L56.57 110Z"
  ]
};
