import _get from "lodash/get";
import cookies from "js-cookie";
import {
  httpSuccessResponseLogger,
  httpErrorResponseLogger
} from "@/helpers/logger";
import api from "@/api";
import _ from "lodash";
import store from "@/store";

export default function({ next, to }) {
  const auth_token = cookies.get("auth_token")
    ? JSON.parse(cookies.get("auth_token"))
    : {};

  api
    .get("/system-configs/maintenance")
    .then(response => {
      httpSuccessResponseLogger(response);

      const maintenance = _get(response, "data.result.value");

      if (maintenance === "active" && !_.isEmpty(auth_token)) {
        store.commit("auth/INVALIDATE_AUTH_TOKEN");
      }

      if (maintenance === "active" && _.isEmpty(auth_token)) {
        return next({
          name: "Error423",
          params: { "0": to.path },
          force: true
        });
      }

      return next();
    })
    .catch(error => {
      httpErrorResponseLogger(error);

      const status = _get(error, "response.status");

      switch (status) {
        case 500:
          return next({
            name: "Error423",
            params: { "0": to.path },
            force: true
          });
        default:
          return next({
            name: "Error500",
            params: { "0": to.path },
            force: true
          });
      }
    });
}
