export default {
  prefix: "fapzse",
  iconName: "refresh",
  icon: [
    22,
    22,
    [],
    "asfcbc115",
    "M10.997 0C6.5396 0 2.90204 3.64217 2.90204 8.10526V13.4153L1.98503 12.4971C1.76707 12.2732 1.4678 12.1466 1.15498 12.1466C0.684047 12.1477 0.26168 12.4327 0.0843755 12.8691C-0.0917992 13.3056 0.0132279 13.8065 0.349767 14.1345L3.13807 16.9263C3.35716 17.2158 3.69935 17.3843 4.06186 17.3843C4.42437 17.3831 4.76543 17.2124 4.98339 16.9218L7.76718 14.1345C8.06984 13.8439 8.19067 13.413 8.08565 13.0071C7.97949 12.6012 7.66328 12.2845 7.25785 12.1782C6.85242 12.0731 6.42215 12.1941 6.13191 12.4971L5.2149 13.4153V8.10526C5.2149 4.89391 7.78976 2.31579 10.997 2.31579C12.2608 2.31579 13.4138 2.7206 14.3681 3.40923C14.7024 3.65913 15.1462 3.70888 15.5279 3.53927C15.9096 3.36965 16.1705 3.00781 16.21 2.59169C16.2507 2.17558 16.0632 1.77076 15.721 1.53104C14.3941 0.574424 12.7588 0 10.997 0ZM17.9187 4.61575C17.5629 4.6214 17.2287 4.79102 17.0141 5.07484L14.2269 7.86554C13.9243 8.15615 13.8034 8.58697 13.9084 8.99291C14.0146 9.39885 14.3308 9.71546 14.7362 9.82175C15.1417 9.92691 15.5719 9.80592 15.8622 9.50288L16.7792 8.5847V13.8947C16.7792 17.1061 14.2043 19.6842 10.997 19.6842C9.73333 19.6842 8.58029 19.2794 7.62601 18.5908C7.29173 18.3409 6.84791 18.2911 6.46619 18.4607C6.08448 18.6303 5.82361 18.9922 5.78408 19.4083C5.74343 19.8244 5.93089 20.2292 6.27308 20.469C7.60004 21.4256 9.2353 22 10.997 22C15.4545 22 19.0921 18.3578 19.0921 13.8947V8.5847L20.0091 9.50288C20.2993 9.80592 20.7296 9.92691 21.135 9.82175C21.5404 9.71546 21.8566 9.39885 21.9628 8.99291C22.0678 8.58697 21.947 8.15615 21.6443 7.86554L18.8526 5.07031C18.6302 4.77971 18.2846 4.61123 17.9187 4.61575Z"
  ]
};
