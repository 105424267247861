export default {
  prefix: "fapzse",
  iconName: "comments",
  icon: [
    20,
    20,
    [],
    "asfcbc82",
    "M19.9862 3.69697C19.9687 1.40947 18.6509 0.0353903 16.4745 0.0116993C14.2981 -0.0119918 12.1516 0.0116993 9.98519 0.0116993C7.78132 0.0116993 5.57746 -0.0146241 3.3736 0.0116993C1.51417 0.040655 0.0990052 1.32787 0.0515835 3.20473C-0.023293 6.31615 -0.0108136 9.43284 0.0515835 12.5443C0.081534 14.1684 1.21716 15.6373 2.71718 15.6873C4.07245 15.732 4.12985 16.398 4.02253 17.4352C4.00007 17.651 4.02253 17.8748 4.02253 18.0932C3.98259 18.8329 4.07494 19.4884 4.80873 19.8569C5.54252 20.2254 6.05667 19.8174 6.48596 19.3647C9.25389 16.4507 12.516 15.2529 16.472 15.7452C18.6134 16.0084 19.9662 14.3974 19.9812 12.1152C20.0045 9.30912 20.0061 6.50305 19.9862 3.69697ZM11.9345 10.5411C9.98269 10.5411 8.03091 10.5411 6.07913 10.5411C5.51007 10.5411 5.0109 10.4015 5.04584 9.72503C5.08078 9.04852 5.60242 8.96165 6.13903 8.96692C7.13739 8.96692 8.13574 8.96692 9.13409 8.96692C10.0476 8.96692 10.9611 8.96692 11.8796 8.96692C12.4162 8.96692 12.9478 9.04062 12.9703 9.72766C12.9927 10.4147 12.4836 10.5411 11.9295 10.5384L11.9345 10.5411ZM13.8862 6.85578C12.5584 6.85578 11.2281 6.85578 9.89284 6.85578C8.60497 6.85578 7.31709 6.85578 6.02922 6.85578C5.53004 6.85578 5.0683 6.711 5.03086 6.11346C5.00091 5.42379 5.48511 5.27111 6.04419 5.27111C8.66154 5.27111 11.2781 5.27111 13.8937 5.27111C14.4428 5.27111 14.9545 5.38694 14.942 6.07661C14.9295 6.76628 14.4254 6.85842 13.8813 6.85578H13.8862Z"
  ]
};
