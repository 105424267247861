export default {
  prefix: "fapzse",
  iconName: "engineering",
  icon: [
    15,
    20,
    [],
    "asfcbc90",
    "M7.4883 5.46305H2.84593C2.3581 5.46305 1.76362 5.54903 1.65017 4.91325C1.53672 4.27747 2.02228 4.08968 2.58046 4.10552C3.00249 4.11909 3.01384 3.73898 3.14998 3.50368C3.69099 2.5484 4.52988 1.79531 5.53923 1.35877C6.11329 1.10536 6.80987 1.13251 7.07535 0.284053C7.21829 -0.168459 7.91714 -0.0440181 8.03967 0.401706C8.21211 1.01939 8.6228 1.06237 9.09022 1.2117C10.0248 1.52383 10.8448 2.10677 11.4454 2.886C11.8788 3.42449 12.015 4.28878 13.0201 4.22996C13.2788 4.21412 13.4739 4.61912 13.3559 4.95624C13.213 5.38386 12.8613 5.46758 12.4483 5.46532C10.7965 5.45853 9.14013 5.46305 7.4883 5.46305Z" +
      "M12.3213 6.82059C11.8856 9.14197 10.0523 10.7552 7.82183 10.8932C5.59141 11.0312 3.46081 9.59449 2.80054 7.59665C2.6349 7.09888 2.57364 6.79117 3.32694 6.80249C6.30387 6.84548 9.2808 6.82059 12.3213 6.82059Z" +
      "M14.9964 14.7871C14.9442 13.3616 14.3429 12.7145 12.9294 12.7055C11.1142 12.6942 9.29896 12.7055 7.49964 12.7055C5.65267 12.7055 3.80344 12.6919 1.95647 12.7055C0.731215 12.7191 0.100433 13.3616 0.0142111 14.5653C-0.11966 16.4681 0.694911 17.9003 2.34674 18.7239C5.75024 20.4276 9.25358 20.4231 12.6662 18.7239C14.2204 17.9524 15.0622 16.6084 14.9964 14.7871ZM7.89445 18.604C6.11328 18.5836 6.0974 18.6379 6.63742 16.9071C6.92558 15.9839 7.393 15.0812 6.47859 14.0789H8.45035C7.4883 15.321 8.2416 16.4274 8.57741 17.5496C8.78389 18.2352 8.8497 18.613 7.89445 18.604Z"
  ]
};
